import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E13",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page34/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Choose the correct word.
				`,
        color: "rgb(244,130,39)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `up / out`,
          `pass / spend`,
          `win / earn`,
          `down / up`,
          `settled / taken`,
          `look / tie`,
        ],
        answers: ["0-4", "1-4", "2-4", "3-4", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div>

      They’re getting married and plan to <strong> get / <span  style="border-radius: 50%; border: 2px solid; padding:3px">have</span></strong> a 
      family.<br>

      <div style="margin-top: 5px; display: flex; gap: 20px;">
        <div style="font-weight: bold; text-align: right;">1</div>
        <div>
        They decided to take some time <input id='0' type='Circle' />  some time abroad in 
        travel before university.
        </div>
      </div>
      <div style="margin-top: 5px; display: flex; gap: 20px;">
        <div style="font-weight: bold; text-align: right;">2</div>
        <div>
        We always <input id='1' type='Circle' /> some time abroad in 
        the holidays.
         
        </div>
      </div>
      <div style="margin-top: 5px; display: flex; gap: 20px;">
        <div style="font-weight: bold; text-align: right;">3</div>
        <div>
        It’s a nice job, but I don’t <input id='2' type='Circle' />  much money.
         
        </div>
      </div>
      <div style="margin-top: 5px; display: flex; gap: 20px;">
      <div style="font-weight: bold; text-align: right;">4</div>
      <div>
      I’d like to take <input id='3' type='Circle' /> a new sport. Which 
one do you recommend?
       
      </div>
    </div>
    <div style="margin-top: 5px; display: flex; gap: 20px;">
    <div style="font-weight: bold; text-align: right;">5</div>
    <div>
    My uncle has never <input id='4' type='Circle' /> down and 
    got married.
     
    </div>
  </div>
  <div style="margin-top: 5px; display: flex; gap: 20px;">
  <div style="font-weight: bold; text-align: right;">6</div>
  <div>
  Elena doesn’t want to <input id='5' type='Circle' /> down 
yet with a family and a house.
   
  </div>
</div>
    </div>
			  `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page34/E2/key/E2answerkey.png",
    component: T6v2,
    inputSize: 120,
    maxLength: 15,
    stylesTextInput: {
      fontSize: "20px",
      background: "none",
      // border: "none",
      textAlign: "center",
      height: 27,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete Jen’s essay with the correct 
        form of the verbs.
				`,
        color: "rgb(244,130,39)",
        star: 2,
      },
    ],
    hintBox: [
      {
        src: [
          "enjoy",
          "get",
          "<s>have</s>",
          "look",
          "make",
          "retire",
          "start",
          "travel",
          "waste",
        ],
        borderColor: "rgb(244,130,39)",
        width: 800,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div style="position:relative; "> 
        <img src="img/FriendsPlus/Page34/E2/1.jpg" style="width:18cm; height: 24.5cm"/>
        		
         <div style="position:absolute; top: 353px; left: 77px; width: 536px;font-size: 20px;  line-height: 32px; ">
         <b style='font-size: 24px'>My future plans</b><br>
         I'm at school now, but I’ve got some exciting plans 
         for the future, I <u style='color: gray'>&ensp;'m having&ensp;</u> a lot of fun and <sup>1</sup>#
          myself at school, but I know I won't be here forever. if I have time after my exams, I <sup>2</sup>#
          around India. I'd like to <sup>3</sup># involved in a bit of charity work before I go to university.<br>
          After my travels, I’ll start my degree. People like to <sup>4</sup># after themselves these days, and I’m
          planning to study personal fitness. After university, I doubt that I’ll <sup>5</sup># time working for someone
          else for long. I’m hoping to <sup>6</sup># up my own 
          business when I’m around thirty, and by the time I’m 
          forty-five, I want to <sup>7</sup># my first million 
          pounds! Then I can <sup>8</sup># young, and really enjoy life!


         </div>
        </div>
      `,
        answer: [
          `enjoying`,
          `'ll travel`,
          `get`,
          `look`,
          `waste`,
          `start`,
          `make`,
          `retire`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E5",
    component: T6v2,
    audio: "Audios/1-11 Friends Plus 8_WB.mp3",
    exerciseKey: "img/FriendsPlus/Page34/E3/Key/E3answerKey.png",
    // hideBtnFooter: true,
    textareaStyle: { width: 730 },
    inputSize: 120,
    stylesTextInput: {
      background: "none",
      border: "none",
      fontSize: "23px",
      borderBottom: "2px  dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name="11"></headphone>&ensp;
             Listen to Bruno, a TV presenter, 
        talking about a time capsule. Write <i>true</i> or <i>false</i>.
				`,
        color: "rgb(244,130,39)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				Bruno works on a TV programme for older people. 
        <strong style="text-decoration:underline; color: gray">false</strong>
      <div style="display:flex;flex-direction:column; line-height: 50px">
      <span><strong style="margin-right:30px">1</strong>They want people to say what should go into 
      the time capsule. #</span>
      <span><strong style="margin-right:30px">2</strong>They’re going to open the time capsule in 200 
      years. #</span>
      <span><strong style="margin-right:30px">3</strong>They have already put some things into the 
      time capsule. #</span>
      <span><strong style="margin-right:30px">4</strong>Bruno makes predictions about life in the 
      future. #</span>
      <span><strong style="margin-right:30px">5</strong>Bruno is advertising a competition. #</span></div>
        `,
        answer: [`true`, `false`, `true`, `false`, `true`],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E4",
    audio: "Audios/1-12 Friends Plus 8_WB.mp3",
    exerciseKey: "img/FriendsPlus/Page34/E4/key/E4answerkey.png",
    component: T6v2,
    inputSize: 120,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "23px",
      borderBottom: "2px  dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: ` <headphone name="12"></headphone>&ensp;
        Now listen to three responses 
        from David, Lena and Claire. Write the speaker’s 
        name next to the ideas.
				`,
        color: "rgb(244,130,39)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <strong style="text-decoration:underline; color: gray">Lena</strong> loves reading
					<div style='display: flex; gap: 20px; flex-direction: column;'>
          <span><strong style="margin-right:30px">1</strong>#  is excited about the future.</span>
        
          <span><strong style="margin-right:30px">2</strong>#  has got a busy life now.</span>
					
          <span><strong style="margin-right:30px">3</strong>#  thinks we’ll work less.</span>
					
          <span><strong style="margin-right:30px">4</strong>#  thinks we’ll see friends less.</span>
					
          <span><strong style="margin-right:30px">5</strong>#  wants to keep his / her laptop.</span>
					
          <span><strong style="margin-right:30px">6</strong>#  thinks exercise is important.</span>
					
					</div>
        `,
        answer: [`Lena`, `Claire`, `Lena`, `David`, `David`, `Claire`],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page34/E4/key/answerkey.png",
    component: T6v2,
    inputSize: 320,
    hideBtnFooter: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "1px  dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Write your own ideas about life in 
        100 years’ time. Think about:
				`,
        color: "rgb(244,130,39)",
        star: 3,
      },
    ],
    textareaStyle: {
      width: 900,
    },
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style="display:flex;flex-direction:column">

          <div style="display:flex;flex-direction:row">
          
          <ul style="margin-right:300px">
           <li>travel</li>
           <li>work</li>
           <li>education</li>
          </ul>

          <ul>
          <li> entertainment</li>
          <li>the environment</li>
          <li>health and medicine</li>
         </ul>


          
          </div>


          <strong style="text-decoration:underline">I think we’ll live longer and retire later. We might all
          travel in electric cars and ...</strong>

          <textarea id="0" rows=8></textarea>
          
          
          
          </div>
        `,
        answer: [
          `He cycled over a volcano.`,
          `She was sixteen.`,
          `No`,
          `The boy was from the USA, and the girl was from India.`,
          `The surfer, Garrett McNamara, has the most votes.`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P34-E4",
    audio: "Audios/1-12 Friends Plus 8_WB.mp3",
    exerciseKey: "img/FriendsPlus/Page34/E4/key/answerkey.png",
    component: T6v2,
    inputSize: 450,
    maxLength: 400,
    hideBtnFooter: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "1px  dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "6",
        title: `<headphone name='12' src='/Audios/1-12 Friends Plus 8_WB.mp3'></headphone>&ensp;
        Listen to David, Lena and Claire again. Are you more positive or negative about the future? Explain why.
				`,
        color: "rgb(244,130,39)",
        star: 3,
      },
    ],
    textareaStyle: {
      width: 900,
      height: 400,
    },
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style="display:flex;flex-direction:column">
          <strong style="text-decoration:underline; color: gray">&ensp;I’m more positive than David because I think we’ll still <br>
          travel to see friends, but I’m more negative ... </strong>

          <textarea id="0" rows=8></textarea>

          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
