import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P10-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page10/Key/E1answerKey.jpg",
    component: MatchDots,
    maxLength: 1,
    stylesTextInput: { borderBottom: "dotted" },
    fontSize: 30,
    inputSize: 35,
    titleQuestion: [
      {
        num: "1",
        title: "Match words 1–6 with a–f.",
        color: "rgb(249,162,95)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "225px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "275px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "400px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, //5
          // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "75px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "275px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
          // 12
        ],
        answers: ["0-5", "1-9", "2-8", "3-7", "4-6"],
        initialValue: [],
      },
      Layout: `
      
          <div style='display:flex;margin-left:170px;margin-top:40px;margin-bottom:50px'>
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          
        
          <img src='img/FriendsPlus/Page10/E1/1.jpg'  /></div>
  
        </div>
      
        
      `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P10-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page10/Key/E2answerKey.png",
    component: T6,
    // maxLength: 1,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "23px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Complete the words in the text.`,
        color: "rgb(249,162,95)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 23px'>
        <div style=''>
            <img src="/img/FriendsPlus/Page10/E2/1.jpg" style='height:18cm;'  >
        </div>
        <div style='position: absolute;  top: 61px; left: 87px; width: 618px; line-height: 40px'>
        Every decade has a different idea 
        about what styles are <b>c</b><u style='color: gray'>&ensp;ool&ensp;</u> and 
        fashionable. Fashion designer Alice McClaren 
        says this is especially true of <sup>1</sup><b>t</b># .<br>

        ‘Jeans will always be popular,’ she says, 
        ‘but the very thin, tight styles of today's 
        ‘skinny jeans’ are very different from what 
        people used to wear. In the 1970s, for example, 
        everyone had big, <sup>2</sup><b>b</b># flared 
        jeans. They were sometimes plain, but usually<br> 
        <sup>3</sup><b>p</b># and <sup>4</sup><b>c</b># , with 
        big, bright flowers. Girls used to have very 
        long dresses and <br><sup>5</sup><b>s</b># , often down 
        to their feet. And on top, they used to wear 
        pretty, feminine <sup>6</sup><b>b</b># . Long-sleeved shirts and tops were more popular 
        than short-sleeved ones.’
        </div>
       
    
      </div>
        `,
        answer: ["rousers", "aggy", "atterned", "olourful", "kirts", "louses"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P10-E3",
    audio: "/Audios/1-03 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page10/Key/E3answerKey.jpg",
    component: T6,
    // maxLength: 1,
    inputSize: 130,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `<headphone src="/Audios/1-03 Friends Plus 8_WB.mp3" name="03"></headphone> Listen to an interview with Dan 
        and Tina about the clothes that were in fashion 
        when they were young. Write the correct names.`,
        color: "rgb(249,162,95)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
          <div><strong style="margin-right:20px">Tina</strong> ’s brother influenced her style.</div>
          <div><strong style="margin-right:15px">1</strong># talks about the 1980s.</div>
          <div><strong style="margin-right:15px">2</strong># talks about the 1990s.</div>
          <div><strong style="margin-right:15px">3</strong># says that the styles of the past 
          are in fashion again now.</div>
          <div><strong style="margin-right:15px">4</strong># used to like how teenage 
          fashions change very quickly.</div>
          <img src="/img/FriendsPlus/Page10/E3/1.jpg" style="margin-left:200px">
        </div>

        `,
        answer: ["Tina", "Dan", "Dan", "Tina"],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P10-E4",
    audio: "/Audios/1-03 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page10/Key/E4answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 230,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "4",
        title: `<headphone src="/Audios/1-03 Friends Plus 8_WB.mp3" name="03"></headphone>&ensp; Listen again and choose the 
        correct answers.`,
        color: "rgb(249,162,95)",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 150 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 6,
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "black",
        },
        limitSelect: 1,
        listWords: [
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
          `
          a <br> b <br> c
          
          
          `,
        ],
        answers: [
          "0-22",
          "1-26",
          "2-26",
          "3-22",
          "4-30",
          "5-26",
          "6-22",
          "7-22",
        ],
        initialValue: [],
      },
      Layout: `
      <div style="margin-top:35px;margin-left:30px">
     
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='0' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">1 </span>Tina says there used to be <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> of fashion when 
        she was young.</div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px"> more types</div>
        <div style="margin-top:1px">only a small number of types</div>
        <div style="margin-top:1px"> only one type</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='1' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">2 </span> Tina <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> the style of clothes her brother and his friends wore.</div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px"> used to dislike</div>
        <div style="margin-top:1px">used to like</div>
        <div style="margin-top:1px">didn’t use to care about</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='2' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">3 </span>Tina says that punks used to wear <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> trousers. </div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px">colourful </div>
        <div style="margin-top:1px">tight</div>
        <div style="margin-top:1px">smart</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='3' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">4 </span>Tina’s parents <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> the clothes she wore.</div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px"> used to be unhappy about</div>
        <div style="margin-top:1px"> didn’t use to be interested in</div>
        <div style="margin-top:1px">used to love</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='4' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">5 </span>At first, Dan <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> fashion. </div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px"> wanted to study</div>
        <div style="margin-top:1px">didn’t have money for</div>
        <div style="margin-top:1px">wasn’t into</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='5' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">6 </span>Dan says his hip hop clothes used to be <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> .</div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px"> easy to find</div>
        <div style="margin-top:1px">  very large </div>
        <div style="margin-top:1px">messy</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='6' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">7 </span>Dan didn’t use to <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> very often.</div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px"> listen to music </div>
        <div style="margin-top:1px">  buy new trainers </div>
        <div style="margin-top:1px"> brush his hair</div>
        </div>
        </div>
        
      </div>
      <div style=" display: flex; margin-top:30px">
      
      <div>
        <input id='7' type='Circle'/>
        </div>
        <div style="margin-left:30px; margin-top:-38px">
        <div style="margin-left:-80px"><span style="font-weight: bold;margin-right:5px">8 </span> When Dan was young, he used to think fashion was <u >&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> .</div>
        <div style="margin-top:46px ; margin-top:-0px">
        <div style="margin-top:-1px">very important </div>
        <div style="margin-top:1px">funny</div>
        <div style="margin-top:1px">expensive</div>
        </div>
        </div>
        
      </div>
      
        
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P10-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 1,
    inputSize: 230,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "5",
        title: `Interview your parents about fashion 
        when they were young, or choose a period in the 
        past. Write four or five sentences about what 
        people used to wear.`,
        color: "rgb(249,162,95)",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    textareaStyle: { width: 900 },
    questions: [
      {
        title: `
        <div>
          <div><strong style="">My parents were young in the 1980s. Girls used to wear
          short skirts and colourful, baggy tops ...</strong> </div>
          <textarea id="0" rows="5"></textarea>
        </div>

        `,
        answer: [""],
      },
    ],
  },
};

export default json;
