import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P13-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "/img/FriendsPlus/Page13/Key/E1answerKey.jpg",
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words.`,
        color: "rgb(0,168,78)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "2px solid rgb(0, 173, 238)",
          padding: "5px",
        },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "as / like",
          "For / By",
          "As / For",
          "as / than",
          "like / such",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-0"],
        initialValue: [],
      },
      Layout: `
			  <div>
          <div>Before the 1960s, there didn’t use to be much pop 
          music. Styles <strong><span style="border: 3px solid black;border-radius:50%;padding:5px">such</span> / so</strong> as rock ’n’ roll changed 
          this.</div>
          <div><strong style="margin-right:15px">1</strong>With groups <input id="0" type="Circle"> The Beatles, more and 
          more teenagers started to like pop.</div>
          <div><strong style="margin-right:15px">2</strong>‘Beatlemania’ made fans do crazy things. <input id="1" type="Circle"> Texample, they threw clothes at the singers.</div>
          <div><strong style="margin-right:15px">3</strong>Normal life became difficult for the group. <input id="2" type="Circle">  instance, fans followed them everywhere.</div>
          <div><strong style="margin-right:15px">4</strong>Public events, such <input id="3" type="Circle"> concerts, were 
          difficult because the fans screamed so loudly.</div>
          <div><strong style="margin-right:15px">5</strong>Now, Beatles’ songs <input id="4" type="Circle">  ‘Yesterday’ and 
          ‘Hey Jude’ are some of the most famous pop 
          songs of all time.</div>
			  </div>
			  `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P13-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page13/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 90,
    inputSize: 850,
    stylesTextInput: {
      background: "none",
      // border: "none",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Order the words to make sentences 
        about life in the nineties.`,
        color: "rgb(0,168,78)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='font-size: 22px; line-height: 45px'>
          <div>clothes / used / such as / people / to wear / 
          big T-shirts / baggy</div>
          <u style='color: gray'><b>People used to wear baggy clothes, such as big T-shirts.</b></u>
          <div><strong style="margin-right:15px">1</strong>by artists / electronic / Moby / like / music / 
          was popular</div>
           #
          <div><strong style="margin-right:15px">2</strong>for instance / to buy / people / started / 
          electronic gadgets / mobile phones </div>
           #
          <div><strong style="margin-right:15px">3</strong>easyJet / became / for example / cheaper / 
          flying / with new airlines </div>
           #
          <div><strong style="margin-right:15px">4</strong>started / in online chatrooms / people / talking / 
          AOL Instant Messenger / such as</div>
           #
          </div>
        `,
        answer: [
          "Electronic music by artists like Moby was popular.",
          "People started to buy electronic gadgets, for instance mobile phones.",
          "Flying became cheaper with new airlines, for example, easyJet.",
          "People started talking in online chatrooms, such as AOL Instant Messenger.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P13-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page13/Key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `Read about the nineties craze for 
        Tamagotchi electronic pets. Complete the fact file 
        with the words and phrases.`,
        color: "rgb(0,168,78)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:710px;margin-left:130px;margin-bottom:20px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(0,168,78);'>
          <div style="margin-left:30px"><span style="margin-right:20px">in 1996</span><span style="margin-right:20px;text-decoration:line-through">in the nineties</span><span style="margin-right:20px">like</span><span>such</span></div>
          <div><span style="margin-right:20px">this decade</span><span style="margin-right:20px">those days</span><span>until years later</span></div>
          
        </div>
        
      </div>
      <div style='position: relative; font-size: 20px'>
        
            <img src="/img/FriendsPlus/Page13/E3/1.jpg" style='height:27cm;'  >
            <div style='position: absolute; top: 197px; left: 100px; width: 417px'>
            When was it?<br>
            <u style='color: gray'>&ensp;In the nineties&ensp;</u>, people started 
            to buy electronic gadgets to play 
            with instead of old-style toys. 
            During <sup>1</sup># , they could 
            buy gadgets <sup>2</sup># as 
            games consoles. <sup>3</sup># ,<br> 
            one of these gadgets, the Tamagotchi, became a 
            craze.
            </div>
            <div style='position: absolute; top: 510px; left: 100px; width: 535px'>
              When was it?<br>
              In <sup>4</sup># , gadgets <sup>5</sup># mobile 
              phones were expensive. Apps didn’t become 
              popular <sup>6</sup># . However, Tamagotchis 
              were cheap and fun for children. They were 
              ‘electronic pets’ that you could carry around in your 
              pocket. Users used to feed their pet and play games 
              with it. They needed to look after their pet well to 
              keep it alive!<br>
              Where is it now?<br>
              Amazingly, Tamagotchis are back! You can buy one 
              online or in shops today. However, most children 
              prefer apps or computer games these days. 
            </div>
        </div>
      </div>
        `,
        answer: [
          "this decade",
          "such",
          "In 1996",
          "those days",
          "like",
          "until years later",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P13-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 800,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      height: 130,
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Write a fact file about a craze you 
        remember from the past few years. Use the fact 
        file in exercise 3 to help you. Include the phrases 
        on this page and information about these things:`,
        color: "rgb(0,168,78)",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
         <div display: flex; flex-direction: column; font-size: 22px>
          <div><strong>When was it?</strong>
          <ul>
            <li>When did it happen?</li>
            <li>Why was it popular?</li>
          </ul>
          <textarea id='0' rows='3'></div>
          <div><strong>What was it?</strong>
          <ul>
            <li>Was it fashion / music / entertainment / 
            technology / a toy? Describe the craze.</li>
          </ul>
          <textarea id='1' rows='3'></div>
          <div><strong>Where is it now?</strong>
          <ul>
            <li>Is it popular now? Why? / Why not?</li>
          </ul>
          <textarea id='2' rows='3'></div>
         </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
