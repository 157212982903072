import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page22/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "24px",
    },
    hintBox: [
      {
        src: [
          "around / through",
          "on / off",
          "over / under",
          "<del>up / down</del>",
        ],
        borderColor: "rgb(245, 128, 37)",
        width: 650,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `
				Label the pictures with the correct pair of words.`,
        color: "rgb(245, 128, 37)",
        star: 1,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <center><hintBox id='0'></hintBox></center>
				<div style="padding-left:130px"><img src='img/FriendsPlus/Page22/E1/1.jpg' width='70%' /></div>

				<div style='padding-left:90px; margin-top: 20px; display: flex; gap: 100px;'>
					<div style='display: flex; gap: 20px; flex-direction: column;'>
            <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'></span>
              <div>&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;&nbsp;<b>up</b></div>
            </div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>A #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>A #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>A #</div>
						</div>
					</div>
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<div>B&nbsp;&nbsp;&nbsp;&nbsp;<b>down</b></div>
						</div>
            <div style='display: flex; gap: 10px;'>
							<div>B #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<div>B #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<div>B #</div>
						</div>
					</div>
				</div>
        `,
        answer: ["on", "around", "over", "off", "through", "under"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page22/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 950,
    stylesTextInput: {
      fontSize: "20px",
      background: "none",
      borderBottom: "none",
    },
    hintBox: [
      {
        src: ["climb", "cycle", "<del>dive</del>", "sail", "surf", "swim"],
        borderColor: "rgb(245, 128, 37)",
        width: 650,
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete Helen's email with the correct form of the verbs`,
        color: "rgb(245, 128, 37)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `        
        <div style='padding-left:150px;'><hintBox id='0'></hintBox></div>
					

      <div style='position:relative'>
          <div style='padding-left:200px;'><img src='img/FriendsPlus/Page22/E2/1.jpg' /></div>

          <div style=" position: absolute; width: 128px; top: 229px; left: 259px; "><input id='0' value='text' width=''></input></div>
          <div style=" position: absolute; width: 128px; top: 260px; left: 365px; "><input id='1' value='text' width=''></input></div>
          <div style=" position: absolute; width: 128px; top: 290px; left: 510px; "><input id='2' value='text' width=''></input></div>
          <div style=" position: absolute; width: 128px; top: 435px; left: 501px; "><input id='3' value='text' width=''></input></div>
          <div style=" position: absolute; width: 128px; top: 464px; left: 337px; "><input id='4' value='text' width=''></input></div>

       

				</div>
        `,
        answer: ["sailed", "swimming", "surf", "climbed", "cycled"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E3",
    audio: "/Audios/1-07 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page22/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 180,

    titleQuestion: [
      {
        num: "3",
        title: `
			<headphone src="/Audios/1-07 Friends Plus 8_WB.mp3" name="07"></headphone>&emsp;Choose the correct words to complete Amber's message.
					`,
        color: "rgb(0,103, 178)",
        star: 2,
      },
    ],
    //isHiddenCheck: false,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          //fontStyle: "italic",
          color: "",
          fontSize: 21,
          border: "none",
          borderRadius: "60%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid", padding: "5px" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: ["a <br>|b <br> |c ", "a <br>|b <br> |c"],
        answers: ["0-10", "1-10"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative;'>
                    <b>1</b>&ensp;make bigger in size or number
                    <div style='justify-content:space-between;width:17cm;margin-left:20px'>
                      <input id='0'  type='Circle' />
                    </div>

                    <b>2</b>&ensp;not use and keep for future use
                    <div style='justify-content:space-between;width:17cm;margin-left:20px'>
                      <input id='1'  type='Circle' />
                    </div>
                         
            <div style=" position: absolute; top: 37px; left: 50px;">
                <div>
                  to advertise adventure holidays<br>
                  to hear adventure travel stories<br>
                  to find out more information about adventure travel<br><br>
                  the cost of adventure travel<br>
                  the activities which are the most popular<br>
                  the different types of people going on adventure holidays<br>
                
              </div>
      </div>  
          
`,
    },
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E4",
    audio: "/Audios/1-07 Friends Plus 8_WB.mp3",
    exerciseKey: "img/FriendsPlus/Page22/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 80,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone src="/Audios/1-07 Friends Plus 8_WB.mp3" name="07"></headphone>&emsp;
					Listen again and write <i>true</i> or <i>false</i>.
				`,
        color: "rgb(245, 128, 37)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>The interviewer is at a travel agency. #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>More women than men go on adventure holidays now. #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>Today, a typical adventure traveller is aged twenty-seven. #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>These days, more men travel alone. #</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>5</span>
							<div>More older people enjoy skiing holidays. #</div>
						</div>
            <div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>6</span>
							<div>Adventure holidays today offer more things to do than in the past. #</div>
						</div>
            <div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>7</span>
							<div>Cruise ships that go to adventure destinations are popular with families. #</div>
						</div>
					</div>
        `,
        answer: ["false", "true", "false", "false", "false", "true", "true"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P22-E5",
    component: T6v2,
    inputSize: 490,
    maxLength: 100,
    stylesTextInput: {
      fontSize: "20px",
      background: "none",
      borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				Imagine you are chatting to a travel agency online to organise an adventure holiday for you and some friends. Answer the agent's questions with your own ideas.
				`,
        color: "rgb(245, 128, 37)",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="padding-left:190px; position: relative">
        <img src='img/FriendsPlus/Page22/E5/1.jpg' style='width: 17cm' />  
          <div style=" position: absolute; width: 300px; top: 272px; left: 278px; "><input id='0' value='text' ></input></div>
          <div style=" position: absolute; width: 300px; top: 409px; left: 278px; "><input id='1' value='text' ></input></div>
          <div style=" position: absolute; width: 300px; top: 547px; left: 278px; "><input id='2' value='text' ></input></div>
      </div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
