import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E1",
    audio: "Audios/1-08 Friends Plus 8_WB.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page24/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone src='public/Audios/1-08 Friends Plus 8_WB.mp3' name='08'></headphone >&emsp;
        Read and listen to the blog. Choose the correct answer.
				`,
        color: "rgb(220, 30, 116)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          // padding: "-30px",
          fontWeight: 600,
          //fontStyle: "italic",
          color: "",
          fontSize: 23,
          border: "none",
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "2px solid",
          padding: "3px",
        },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: ["a <br>|b <br> |c"],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `
      <div style="font-size: 23px">
              <div><img  src="img/FriendsPlus/Page24/E1/1.jpg" style='width: 28cm; height: 19cm'/></div>

               <div style='position: relative;padding-left:20px'>
               <div>
               <div> The blog post is about … .<br>
                 <div style='width:17cm;margin-left:20px'>
                   <input id='0'  type='Circle' />
                 </div>

                 <div style=" position: absolute; top: 38px; left: 70px;">
                        <div>
                        two visits to different exciting places<br>
                        two visits to the same place at different times<br>
                        two different adventure travel activities<br><br>
                        </div>
                  </div>
              </div>
              </div>
      </div>
			`,
    },
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page24/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 900,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "left",
      background: "none",
    },
    textareaStyle: { width: 550, fontSize: 22 },
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the blog again and answer the questions.
				`,
        color: "rgb(220, 30, 116)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-size: 22px; line-height: 40px'>
          <div><img  src="img/FriendsPlus/Page24/E1/1.jpg" style='width: 27cm; height: 19cm'/></div>
            <div><b>1</b> Where would you find the blog?</div>
            #
            <div><b>2</b> Who is it for?</div>
            #

            <div><b>3</b> What is the main purpose of the <i>My rating</i> review?</div>
            #
          </div>
        `,
        answer: [
          "You would find the blog on a travel review website",
          "It's for people who like adventure travel",
          "The main purpose is to recommend an exciting travel destination",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E3",
    component: T6v2,
    //hideBtnFooter: true,
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page24/Key/E3answerKey.png",
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center	",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the blog again and write <i>true</i> or <i>false</i>.
				`,
        color: "rgb(220, 30, 116)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-size: 23px; line-height: 50px'>
            <div>The Caminito is a very high path. <u style='color: gray'><b>true</b></u></div>
            <div><b>1 </b> The old path wasn't as dangerous as it sounded in the stories.#</div>
            <div><b>2 </b> Workers used the path to reach the Chorro and Gaitanejo Falls in 2010.#</div>
            <div><b>3 </b> You can see beautiful natural features from the path.#</div>
            <div><b>4 </b> More tourists use the new path.#</div>
          </div>
        `,
        answer: ["false", "false", "true", "true"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page24/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 810,
    stylesTextInput: {
      fontSize: "22px",
      //textAlign: "center	",
      background: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete sentences.
						`,
        color: "rgb(220, 30, 116)",
        star: 3,
        //prefix: { icons: ["far fa-comment"], text: "PRONUNCIATION" },
        // prefix: { icons: ['fas fa-pencil-alt'], text: 'PRONUNCIATION' },
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-size: 22px; line-height: 50px'>
            <div>Why was the Caminito so dangerous?</div>
            <u style='color: gray'><i>Because the old path was narrow, high and broken.</i></u>
            <div><b>1 </b> Who does the writer recommend the Caminito path to?</div>
            <div>#.</div>
            <div><b>2 </b> Why does he post an update?</div>
            <div>#.</div>
            <div><b>3 </b> How does the writer's opinion change from his first visit to his second?</div>
            <div>#.</div>
            <div><b>4 </b> What can you say about the character of the writer from the posts?</div>
            <div>#.</div>
          </div>
        `,
        answer: [
          "People who love challenges and experienced rock climbers",
          "Because he had a different experience there five years later",
          "He thinks that the path is less exciting now that it's safer",
          "He loves danger and adventure, and he likes to share his experiences",
        ],
      },
    ],
  },
  5: {
    nit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E5",
    component: T6v2,
    exerciseKey: "img/FriendsPlus/Page24/Key/E5answerKey.png",
    hintBox: [
      {
        src: ["carry on", "leave behind", "set off", "stop off"],
        borderColor: "rgb(220, 30, 116)",
        width: 650,
      },
    ],
    inputSize: 180,
    maxLength: 16,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				VOCABULARY PLUS Complete the sentences with the words.
				`,
        star: 2,
        color: "rgb(220, 30, 116)",

        //prefix: { icons: ["far fa-comment"], text: "" },
      },
    ],
    //hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="padding-left:40px; line-height: 50px">
          <hintBox id='0'></hintBox>
          <div style='margin-top: 30px'><b>1</b> When do you # on your trip?</div>
          <div><b>2</b> Do you need to stop, or can you #?</div>
          <div><b>3</b> On the way home, we'll # at the shops.</div>
          <div><b>4</b> It's hard to # your family and friends.</div>
        </div>
				`,
        answer: ["set off", "carry on", "stop off", "leave behind"],
      },
    ],
  },
  6: {
    nit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P24-E6",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
				Work in pairs. Read the situation. Then prepare and practise a new dialogue. Use the key phrases and the dialogue in exercise 1 to help you.
				`,
        color: "rgb(220, 30, 116)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px; align-items: start;'>
						<div style='order: 2; flex: 1;'><img src='img/FriendsPlus/Page24/E2/1.jpg' width='100%' /></div>

						<div style='order: 1; flex: 1; display: flex; gap: 10px; flex-direction: column;'>
							<div style='width: 520px; background-color: rgba(246, 211, 219); padding: 15px; border-radius: 20px; display: flex; gap: 20px; flex-direction: column;'>
								<div style='border: 2px solid rgb(220, 30, 116); padding: 15px;'>
									<strong>Student A:</strong> 
									You want to go camping in the 
									countryside at the weekend. Listen to what your 
									friend suggests instead and respond.
								</div>
								<div style='border: 2px solid rgb(220, 30, 116); padding: 15px;'>
									<strong>Student B:</strong> 
									You don’t want to go camping in 
									the countryside at the weekend. You fancy 
									watching a film then having dinner. Suggest 
									what type of film and food you want.
								</div>
							</div>

							
						</div>
					</div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
