import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P45-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page45/key/E1answerKey.png",
    component: T6v2,
    maxLength: 60,
    inputSize: 700,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "1",
        star: 1,

        title: `
        Order the words to make sentences. 
						`,
        color: "#1EC3DC",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 23px'>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        this gift / was / said / her. / <s>He</s>/ that / for  <br> He<input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        said / sign / they / to us / might / the petition. / <s>John</s> <br>John<input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        he / <s>Frances</s>/ told / then. / tired / was / me   <br>  Frances<input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        that / hard work. / money / could / raising / be / <s>He</s>/ said <br> He<input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>5</b>
        <div style='margin-left:10px'>
        that / <s>Mary</s>/ she didn’t / her parents / like / told / art.   <br> Mary<input/>
        </div>
        </div>
       
        </div>
			
						`,
        answer: [
          `said that this gift was for her.`,
          `said to us they might sign the petition.`,
          `told me he was tired then.`,
          `said that raising money could be hard work.`,
          `told her parents that she didn't like art.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P45-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page45/key/E2answerKey.png",
    component: T6v2,
    maxLength: 90,
    inputSize: 830,
    textareaStyle: { width: 700 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,

        title: `
        Complete the following sentences. 						`,
        color: "#1EC3DC",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:1.75'>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        ‘Sometimes campaigning works.’    
        <textarea id='0' rows='2'>He said that</textarea>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        ‘Maybe it’s cold outside.’    <textarea id='1' rows='2'>My mum told me that</textarea>
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        ‘It’s dinner time, so they’re obviously at home.’  
        <textarea id='2' rows='2'>John said that</textarea>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        ‘It’s possible you’ll love that job.’ 
        <textarea id='3' rows='2'>The manager told me that</textarea>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>5</b>
        <div style='margin-left:10px'>
        ‘I can’t see Peter anywhere.’ 
        <textarea id='4' rows='2'>The teacher said that</textarea>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>6</b>
        <div style='margin-left:10px'>
        ‘We don’t want to have meat for breakfast, but we may have it for lunch.’  
         <textarea id='5' rows='2'> The men told me that</textarea>
        </div>
        </div>
       
        </div>
			
						`,
        answer: [
          `He said that sometimes campaigning worked.`,
          `My mum told me that maybe it was cold outside.`,
          `John said that it was dinner time, so they were obviously at home.`,
          `The manager told me that it was possible I would love that job.`,
          `The teacher said that that he couldn't see Peter anywhere. | she couldn't see Peter anywhere.`,
          ` The men told me that they didn't want to have meat for breakfast, but they might have it for lunch.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P45-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page45/key/E3answerKey.png",
    component: T6v2,
    maxLength: 14,
    inputSize: 500,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,

        title: `
        Complete the text with correct form of the words.`,
        color: "#1EC3DC",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			  <div class="container" style="display: flex; justify-content: center; align-items: center; ">
        <div style="display:flex; width: 500px; border: 1px solid green; padding: 10px 22px; border-radius: 40px;">
          <div style="margin-right: 20px;"><span>can</span></div>
          <div style="margin-right: 20px;"><span>have</span></div>
          <div style="margin-right: 20px;"><span>be (x2)</span></div>
          <div style="margin-right: 20px;"><span>may</span></div>
          <div style="margin-right: 20px;"><span>feel
          </span></div>
        </div>
      </div>
			


      <div style=" position: relative;">
      <div style="display: flex; margin-top: 20px;"><img src="img/FriendsPlus/Page45/E3/1.jpg" style='max-width:100%' />
            
            <div style=" position: absolute; top:325px; left: 299px; "><input id='0' font-size='23px' width='100px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 354px; left: 382px; "><input id='1' font-size='23px' width='100px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 50px; left: 633px; "><input id='2' font-size='23px' width='150px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 83px; left: 744px; "><input id='3' font-size='23px' width='100px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 141px; left: 635px; "><input id='4' font-size='23px' width='150px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 172px; left: 833px; "><input id='5' font-size='23px' width='100px' border-bottom='0px'></input></div>
				</div>

						`,
        answer: [
          `had`,
          `felt`,
          `might | could`,
          `were`,
          `might | could`,
          `was`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P45-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page45/key/E4answerKey.png",
    component: T6v2,
    maxLength: 135,
    inputSize: 850,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "4",
        star: 2,

        title: `
        The following statements are what people think about the <i>100 Happy Days Challenge</i>. Report them beginning with: 						`,
        color: "#1EC3DC",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <b style='color: gray; font-size: 22px'>People said that</b> ... <br>
        <span style='font-size: 22px'>or</span> <b style='color: gray; ; font-size: 22px'>People told me that</b> ... 
        <div style='line-height:45px; font-size: 22px'>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        ‘We will never spend time doing such a thing.’   <br><input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        ‘It helps many feel better in life.’   <br><input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        ‘It’s necessary to share this among your colleagues.’   <br><input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        ‘The simple thing really improves our performance.’ <br><input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>5</b>
        <div style='margin-left:10px'>
        ‘It turns out to be a waste of time.’   <br><input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>6</b>
        <div style='margin-left:10px'>
        ‘We are spreading the happiness easily and quickly.’  <br><input/>
        </div>
        </div>
       
        </div>
			
						`,
        answer: [
          `People said that they would never spend time doing such a thing.`,
          `People told me that it helped many feel better in life.`,
          `People told me that it was necessary to share that among my colleagues.`,
          `People said that the simple thing really improved their performance.`,
          `People said that it turned out to be a waste of time.`,
          `People told me that they were spreading the happiness easily and quickly.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P45-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        star: 3,

        title: `
        What did your family and friends say to you yesterday? Write sentences to report.
        `,
        color: "#1EC3DC",
      },
    ],
    textareaStyle: { width: 1000 },
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

<div>
<textarea id='0' rows='10'></textarea>

</div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
