import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P25-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page25/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match 1–6 with a–f.",

        color: "#2E479B",
        star: 1,
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "138px",
              left: "294px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "red",
              //border: "1px solid",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "276px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "red",
              //border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "218px",
              left: "281px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "yellow",
              //border: "1px solid",
            },
          }, //2

          {
            boxMatchStyle: {
              position: "absolute",
              top: "264px",
              left: "256px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "pink",
              //border: "1px solid",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "230px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "blue",
              //border: "1px solid",
            },
          }, //4
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "412px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "94px",
              left: "412px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "173px",
              left: "412px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "248px",
              left: "412px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "360px",
              left: "412px",
              width: 18,
              height: 18,
              border: "1px solid blue",
              borderRadius: "50%",
              //backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //9
        ],
        answers: ["0-9", "1-8", "2-6", "3-5", "4-7"],
        initialValue: [],
      },
      Layout: `
		  <img style='padding-top:10px; margin-bottom:50px' src="img/FriendsPlus/Page25/E1/1.jpg" />
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
	
			  <input id='3' type= 'boxMatch' />
			  <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
			  <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
			  <input id='8' type= 'boxMatch' />
			  <input id='9' type= 'boxMatch' />
			  
		  `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P25-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page25/Key/E2answerKey.png",
    component: T6,
    isHiddenCheck: true,
    inputSize: 950,
    hintBox: [
      {
        src: ["absolutely", "really", "very"],
        borderColor: "rgb(0, 169, 79)",
        width: 350,
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					Order the words to make sentences.
				`,
        color: "rgb(0, 169, 79)",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
					<div style='font-size: 23px; line-height: 50px'>is / very / becoming / which / scuba-diving / popular / is / a sport</div>
					<u style='font-size: 23px; line-height: 50px; color: gray'><i>Scuba-diving is a sport which is becoming very popular.</i></u>

					<div style='font-size: 23px; line-height: 45px'>
						<div><b>1 </b> takes / is / your / who / over there / money / the person</div>
						<div>#.</div>
						<div><b>2 </b> plan / this / can / where / a website / adventure trips / you / is</div>
						<div>#.</div>
						<div><b>3 </b> where / the / that's / year / I / hostel / volunteered / last</div>
						<div>#.</div>
						<div><b>4 </b> the player / Jay / is / scored / has / the / who / most</div>
						<div>#.</div>
						<div><b>5 </b> I / kayaking / here's / use / the paddle / which / go / to</div>
						<div>#.</div>
					</div>
        `,
        answer: [
          "The person who takes your money is over there",
          "This is a website where you can plan adventure trips",
          "That's the hostel where I volunteered last year",
          "The player who has scored the most is Jay / Jay is the player who has scored the most",
          "Here's the paddle which I use to go kayaking",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P25-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page25/Key/E3answerKey.png",
    component: T6v2,
    stylesTextInput: {
      fontSize: "20px",
      background: "none",
      borderBottom: "2px solid black",
      textAlign: "center",
      height: 24,
      fontWeight: 600,
    },
    textareaStyle: { width: 400, fontSize: "21px" },
    isHiddenCheck: true,
    maxLength: 1,
    inputSize: 230,
    titleQuestion: [
      {
        num: "3",
        title: `
				Read and complete the FAQ page with relative clauses a–f.
				`,
        color: "rgb(0, 169, 79)",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
			<div style='display:flex; position: relative; font-size: 21px'>
				<div>
					<img src='img/FriendsPlus/Page25/E3/1.jpg' style='width: 15cm'/>
					<div style=" position: absolute; width: 55px; top: 186px; left: 242px; "><input id='0' value='text' width=''></input></div>
					<div style=" position: absolute; width: 55px; top: 388px; left: 365px; "><input id='1' value='text' width=''></input></div>
					<div style=" position: absolute; width: 55px; top: 473px; left: 287px; "><input id='2' value='text' width=''></input></div>
					<div style=" position: absolute; width: 55px; top: 629px; left: 166px; "><input id='3' value='text' width=''></input></div>
					<div style=" position: absolute; width: 55px; top: 653px; left: 53px; "><input id='4' value='text' width=''></input></div>
				</div>

				<div style="padding-left:20px; padding-top:20px">
					<div><b>a </b> which interests</div>
					<div><b>b </b> which you can</div>
					<div><b>c </b> <s>which has become</s></div>
					<div><b>d </b> where you can</div>
					<div><b>e </b> which involve</div>
					<div><b>f </b> who do this</div>
				</div>
			</div>
        `,
        answer: [`e`, `a`, `f`, `b`, `d`],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P25-E4",
    audio: "",
    video: "",
    recorder: true,
    component: T6v2,
    hideBtnFooter: true,
    stylesTextInput: {
      textAlign: "center",
    },
    textareaStyle: { width: 1100, fontSize: "24px" },
    hintBox: [
      {
        src: [
          "couch-surfing",
          "glamping",
          "going on safari",
          "the Global Greeter Network",
        ],
        borderColor: "rgb(0, 169, 79)",
        width: 850,
      },
    ],
    isHiddenCheck: true,
    inputSize: 230,
    titleQuestion: [
      {
        num: "4",
        title: `
				Write an FAQ page about one of these adventure travel trends or choose your own. Research the trend and find out what it involves, or use your own knowledge.
				`,
        color: "rgb(0, 169, 79)",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
				<div style="padding-left:65px;"><hintBox id='0'></hintBox></div>
				<div>
					<div>Think of four questions and answers which give useful information about the trend, such as:</div>
					<div><b>•</b> what it is and how it started</div>
					<div><b>•</b> how it works</div>
					<div><b>•</b> how to get involved</div>
					<div><b>•</b> how to find out more</div>
					<div>Use the FAQ page in exercise 3 to help you. Include relative clauses and the phrases on this page.</div>
				</div>
				<textarea id="0" rows="6" >
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P25-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6v2,
    hideBtnFooter: true,
    stylesTextInput: {
      textAlign: "center",
    },
    isHiddenCheck: true,
    inputSize: 230,
    titleQuestion: [
      {
        num: "5",
        title: `
					Follow the steps in the writing guide.
				`,
        color: "rgb(0, 169, 79)",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page25/E5/1.jpg' width='45%' /></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
