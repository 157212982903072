import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P60-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    maxLength: 10,
    inputSize: 130,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the sentences with the words in the box. You can use some words more than once.
									`,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    hintBox: [
      {
        src: [`at`, "every", "in", "on", "once"],
        borderColor: "#6A5EA1",
        width: 400,
      },
    ],
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div style="line-height:45px; font-size: 22px ">
        
          
          We finish school <b><u style='color: gray'>&ensp;at&ensp;</u></b> 4.00 p.m.
            <div><b>1</b>&ensp; They play football # Saturday mornings.</div>
            <div><b>2</b>&ensp; Do you have breakfast # day?</div>
            <div><b>3</b>&ensp; I can’t sleep very well # night.</div>
            <div><b>4</b>&ensp; On weekdays, we wake up # 6.45 a.m.</div>
            <div><b>5</b>&ensp; She does her homework # the evening.</div>
            <div><b>6</b>&ensp; I try to go swimming # a week.</div>
            <div><b>7</b>&ensp; We sometimes go shopping # the weekend.</div>
            <div><b>8</b>&ensp; # Sundays, we visit our grandparents.</div>

          
        </div>
				
									`,
        answer: [`on `, `every`, `at`, `at`, `in`, `once`, `at`, `On`],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P60-E2",
    // audio: "Audios/2.12.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E2answerKey.png",
    component: T6v2,
    maxLength: 85,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign:"center",
      fontSize: "21px",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Write sentences and questions using the present simple or present continuous form.

								`,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="line-height:38px; font-size: 21px">
        <div>
        Ian / usually / have / cereal for breakfast <br>
        <b><u style='color: gray'>&ensp;Lan usually has cereal for breakfast.&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u></b>
        </div>
        <div><b>1</b>&ensp; ‘you / enjoy / this film / ?’ ‘Yes, / I’</div>
        #
        <div><b>2</b>&ensp; we / not like / Indian food</div>
        #
        <div><b>3</b>&ensp; who / he / visit / today / ?</div>
        #
        <div><b>4</b>&ensp; ‘Sara / want / a dog / ?’ ‘No, / she’</div>
        #
        <div><b>5</b>&ensp; ‘what / you / do / now / ?’ ‘I / leave’</div>
        #
        <div><b>6</b>&ensp; Jorge / not do / much this week</div>
        #
        <div><b>7</b>&ensp; we / not watch / often / French films</div>
        #
        <div><b>8</b>&ensp; she usually / study / at the library</div>
        #
    
    </div>
        
			
								`,
        answer: [
          `Are you enjoying this film? Yes, I am.`,
          `We don't like Indian food.`,
          `Who is he visiting today?`,
          `Does Sara want a dog? No, she doesn't.`,
          `What are you doing now? I'm leaving.`,
          `Jorge isn't doing much this week.`,
          `We don't often watch French films.`,
          `She usually studies at the library.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P60-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E3answerKey.png",
    component: Circle_Write,

    // maxLength: 6,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Choose the correct words to make sentences in the past simple.
								`,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `did / liked / was`, //1
          `felt / fell / fallen`, //1
          `He_saw / Did_he_seen / Did_he_see`, //1
          `wrote / written / write`, //1
          `were / went / been`,
          `they_were / was_they / were_they`,
          `began / begun / begin`,
          `had / have / has`,
        ],
        answers: ["0-0", `1-4`, `2-4`, `3-0`, `4-4`, `5-8`, `6-8`, `7-4`],
        initialValue: [],
      },
      Layout: `
      
      <div>Where <b>is / <span style='padding: 3px; border:2px solid; border-radius: 50%'>was</span>/ did</b> he born?</div>
      
        <div style='line-height:2'>
          
        <div><b>1</b>&ensp; ‘Did you like that meal?’ ‘Yes, I <input id='0' type='Circle'/>’.</div>

        <div><b>2</b>&ensp; Maya <input id='1' type='Circle'/> and hurt her leg.</div>
          
        <div><b>3</b>&ensp; <input id='2' type='Circle'/> you?</div>
          
        <div><b>4</b>&ensp; Shane <input id='3' type='Circle'/> six emails. </div>

        <div><b>5</b>&ensp; We <input id='4' type='Circle'/>  to Paris last week. </div>

        <div><b>6</b>&ensp; Where <input id='5' type='Circle'/>? </div>

        <div><b>7</b>&ensp; When did you <input id='6' type='Circle'/> your work? </div>

        <div><b>8</b>&ensp; Sam didn’t <input id='7' type='Circle'/>  much money. </div>
    
        </div>
      `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P60-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page60/key/E4answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
      fontWeight: "bold",
      borderBottom: "dotted",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the sentences. Match the questions with the words in bold. Write a or b.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
          Clare saw <b>Peter</b>. <b><u style='color: gray'>&ensp;b&ensp;</u></b> <br>
          <b>Clare</b> saw Peter. <b><u style='color: gray'>&ensp;a&ensp;</u></b> <br>
          <b>a</b> Who saw Peter? <br>
          <b>b</b> Who did Clare see?
        </div>

		     <div style='line-height:2; display:flex'>

        <div>
        <div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:10px'>
            Jay gave me <b>this</b>.# <br>
            <b>Jay</b> gave me this.#
          </div>
        </div>
        <div style='margin-left:25px'>
        <b>a</b> What did Jay give you? <br>
        <b>b</b> Who gave you this?
        </div>
      </div>

      <div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:10px'>
            Diana lives <b>there.</b># <br>
            <b>Diana</b> lives there.#
          </div>
        </div>
        <div style='margin-left:25px'>
        <b>a</b> Who lives there? <br>
        <b>b</b> Where does Diana live?
        </div>
      </div>

      <div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:10px'>
            <b>They had a party</b>at the beach.# <br>
            They had a party<b>at the beach</b>.#
          </div>
        </div>
        <div style='margin-left:25px'>
        <b>a</b> What happened at the beach? <br>
        <b>b</b> Where did they have a party?
        </div>
      </div>
        </div>

      <div style="margin-left:150px">
        <div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:10px'>
          Gill bought <b>two</b> pairs of jeans.# <br>
          <b>Gill</b> bought two pairs of jeans.#
          </div>
        </div>
        <div style='margin-left:25px'>
        <b>a</b> Who bought two pairs of jeans? <br>
        <b>b</b> How many pairs of jeans did Gill buy?
        </div>
      </div>

      <div>
        <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:10px'>
          Sam watched a <b>film</b>.# <br>
            <b>Sam</b> watched a film.#
          </div>
        </div>
        <div style='margin-left:25px'>
        <b>a</b> What did Sam watch? <br>
        <b>b</b> Who watched a film?
        </div>
      </div>
        </div>
         </div>

        `,
        answer: [`a`, `b`, `b`, `a`, `a`, `b`, `b`, `a`, `a`, `b`],
      },
    ],
  },
};

export default json;
