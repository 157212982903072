import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  // 1: {
  //   unit: "Unit 1",
  //   id: "FP8-WB-demo-2023-U1-P8-E1",
  //   audio: "",
  //   video: "",
  //   // recorder: true,
  //   exerciseKey: "/img/FriendsPlus/Page8/Key/E1answerKey.jpg",
  //   component: Circle_Write,
  //   textAlign: "center",
  //   // maxLength: 1,
  //   inputSize: 80,
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `
  //       Choose the correct words.
  // 			`,
  //       color: "rgb(1,105,184)",
  //       star: 1,
  //     },
  //   ],
  //   questionImage: [],
  //   // hideBtnFooter: true,
  //   isHiddenCheck: true,

  //   question: {
  //     Write: {
  //       inputStyle: {},
  //       answers: [],
  //       initialValue: [],
  //     },
  //     Circle: {
  //       initialWordStyle: {
  //         // padding: 2,
  //         // borderColor: "transparent",
  //         fontWeight: 700,
  //         // color: "rgb(88,147,198)",
  //       },
  //       // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
  //       selectWordStyle: {
  //         // padding: 2,
  //         borderBottom: "2px solid black",
  //         paddingBottom: "-30px",
  //         // borderRadius: "50%",
  //         // borderColor: "",
  //       },
  //       limitSelect: 1,
  //       listWords: [
  //         "toy / fan / follower",
  //         "gadget / speakers / puzzle",
  //         "fan / craze / fad",
  //         "app / view / cube",
  //       ],
  //       answers: ["0-0", "1-8", "2-0", "3-4"],
  //       initialValue: [],
  //     },
  //     Layout: /*html */ `
  //     <div style='line-height:2.5'>
  //      <div style="display: flex; column-gap: 20px;">
  //       <strong></strong>
  //       <div>
  //       Actions: share / post / <span style="text-decoration:line-through">app</span>
  //       </div>
  //      </div>
  //      <div style="display: flex; column-gap: 20px;">
  //       <strong>1</strong>

  //       People: <input id="0" type="Circle" />

  //      </div>
  //      <div style="display: flex; column-gap: 20px">
  //       <strong>2</strong>

  //       Electronics: <input id="1" type="Circle" />

  //      </div>
  //      <div style="display: flex; column-gap: 20px">
  //       <strong>3</strong>

  //       Very popular things: <input id="2" type="Circle" />

  //      </div>
  //      <div style="display: flex; column-gap: 20px">
  //       <strong>4</strong>
  //       Things you can buy: <input id="3" type="Circle" />
  //      </div>

  //     </div>
  //     `,
  //   },
  // },
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P8-E1",
    audio: "Audios/1-14 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page8/Key/E1answerKey.png",

    component: DesignUnderLine,
    totalInput: 4,
    titleQuestion: [
      {
        num: "1",
        title: `
        Choose the correct words.
				`,
        color: "rgb(1,105,184)",
        star: 1,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page8/E1/1.jpg" }],
      // Row 1
      [
        { url: "img/FriendsPlus/Page8/E1/2.jpg" },
        { url: "img/FriendsPlus/Page8/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E1/4.jpg" },
        { url: "img/FriendsPlus/Page8/E1/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page8/E1/6.jpg" },
        { url: "img/FriendsPlus/Page8/E1/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page8/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/9.jpg" }],
      // Row 1
      [
        { url: "img/FriendsPlus/Page8/E1/10.jpg" },
        { url: "img/FriendsPlus/Page8/E1/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page8/E1/12.jpg" },
        { url: "img/FriendsPlus/Page8/E1/13.jpg", input: 2 },
        { url: "img/FriendsPlus/Page8/E1/14.jpg" },
        { url: "img/FriendsPlus/Page8/E1/15.jpg", input: 2, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/16.jpg" }],
      // Row 1
      [
        { url: "img/FriendsPlus/Page8/E1/17.jpg" },
        { url: "img/FriendsPlus/Page8/E1/18.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E1/19.jpg" },
        { url: "img/FriendsPlus/Page8/E1/20.jpg", input: 3 },
        { url: "img/FriendsPlus/Page8/E1/21.jpg" },
        { url: "img/FriendsPlus/Page8/E1/22.jpg", input: 3 },
        { url: "img/FriendsPlus/Page8/E1/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/24.jpg" }],
      // Row 1
      [
        { url: "img/FriendsPlus/Page8/E1/25.jpg" },
        { url: "img/FriendsPlus/Page8/E1/26.jpg", input: 4 },
        { url: "img/FriendsPlus/Page8/E1/27.jpg" },
        { url: "img/FriendsPlus/Page8/E1/28.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page8/E1/29.jpg" },
        { url: "img/FriendsPlus/Page8/E1/30.jpg", input: 4 },
        { url: "img/FriendsPlus/Page8/E1/31.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/32.jpg" }],
    ],
  },

  2: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P8-E2",
    audio: "",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 150,
    maxLength: 13,
    exerciseKey: "/img/FriendsPlus/Page8/Key/E2answerKey.png",
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `				
				Complete the sentences with the words.
				`,
        color: "rgb(1,105,184)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='50px; margin-top: 20px; display: flex; gap: 30px;max-width:410px;margin-left:120px;margin-bottom:20px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 4px solid rgb(169,225,236);'>
          <div>
          <span style="margin-right:20px">apps</span><span style="margin-right:20px">fad</span><span style="margin-right:20px">fan</span><span style="margin-right:20px">followers</span><span>post</span></div>
          <span style="margin-right:20px;margin-left:60px">social media</span><span style="text-decoration:line-through">views</span></div>
          
        </div>
        <div style='line-height: 50px; font-size: 22px'>
          <div>The video went viral and received over a million <u><b style="margin-left:15px; color: gray">&ensp;views&ensp;</b></u>.</div>
          <div>
            <strong style="marign-right:20px;">1</strong> Are you a # of any celebrities or sports teams?<br>
            <strong style="marign-right:20px;">2</strong> The website Myspace was a # because it was only popular for a few years.<br>
            <strong style="marign-right:20px;">3</strong> People post comments on # sites like Twitter and Facebook.<br>
            <strong style="marign-right:20px;">4</strong>  A lot of smartphone # are free.<br>
            <strong style="marign-right:20px;">5</strong> My friend wrote a great # about her trip to Canada.<br>
            <strong style="marign-right:20px;">6</strong> She has got over 100,000 # on Twitter.<br>
          </div>
        </div>
        `,
        answer: ["fan", "fad", "social media", "apps", "post", "followers"],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P8-E3",
    audio: "",
    exerciseKey: "/img/FriendsPlus/Page8/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      fontSize: "22px",
      background: "transparent",
      textAlign: "left",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "3",
        title: `				
				Complete the words for the definitions.
				`,
        color: "rgb(1,105,184)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='line-height: 50px; font-size: 22px'>
          <div>something that many people are into for a short time <strong>craze</strong>.</div>
          <div><strong style="margin-right:20px">1</strong>a person who reads another person’s posts online <b>f</b>#</div>
          <div><strong style="margin-right:20px">2</strong>websites for making friends and talking to people <b>s</b>#</div>
          <div><strong style="margin-right:20px">3</strong>the number of times people visit a web page or online video <b>v</b>#</div>
          <div><strong style="margin-right:20px">4</strong>some writing or an image put on social media <b>p</b>#</div>
          <div><strong style="margin-right:20px">5</strong>a computer program for a particular use <b>a</b>#</div>
          <div><strong style="margin-right:20px">6</strong>a small and useful machine or tool  <b>g</b>#</div>
        </div>
        `,
        answer: ["ollower", "ocial media", "iews", "ost", "pp", "adget"],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P8-E4",
    audio: "",
    exerciseKey: "/img/FriendsPlus/Page8/Key/E4answerKey.png",
    component: T6,
    textareaStyle: { width: 600 },
    inputSize: 200,
    stylesTextInput: {
      fontSize: "22px",
      background: "transparent",
      textAlign: "center",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "4",
        title: `				
				Complete the post with words on this page. There may be more than one answer.
				`,
        color: "rgb(1,105,184)",
        star: 3,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='position: relative; font-size: 23px; margin-left: 150px'>
        <div style=''>
            <img src="/img/FriendsPlus/Page8/E4/1.jpg" style='height:21cm;'  >
        </div>
        <div style=" position: absolute; top: 159px; left: 70px; width: 600px; line-height: 39px; font-weight: 500px">
        Today, many of us are mad about social media 
        and are looking for an audience. So, how can 
        you make sure your blog has got more 
        <sup>1</sup># and your YouTube videos get 
        more <sup>2</sup># and likes? One way is to 
        <sup>3</sup># something online every week. 
        People like to see something new, or they get 
        bored quickly! Try to use all the <sup>4</sup>#
        you can. Download popular free <sup>5</sup># 
        such as Instagram and Snapchat for a bigger 
        audience. Another way is to watch the media, 
        so that you know about the next <sup>6</sup># 
        before it happens. Go on Twitter and read the 
        tweets of the biggest celebrities, so you can 
        find out what people are going to be into next. 
        </div>
        
        
        
        
        
    
      </div>
        `,
        answer: [
          "followers/fans",
          "views",
          "post",
          "social media",
          "apps",
          "craze/fad",
        ],
      },
    ],
  },
};

export default json;
