import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P15-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "/img/FriendsPlus/Page15/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `Choose the correct words to complete the table.`,
        color: "rgb(120,199,215)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {
          width: 120,
          textAlign: "center",
        },
        answers: [``],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "3px solid rgb(0, 173, 238)",
          padding: "5px",
        },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "written / wrote",
          "has / have",
          "seen / saw",
          "hasn’t / haven’t",
          "ate / eaten",
          "hasn’t / haven’t",
          "come / came",
          "has / hasn’t",
          "have / haven’t",
        ],
        answers: [
          "0-0",
          "1-0",
          "2-0",
          "3-4",
          "4-4",
          "5-0",
          "6-0",
          "7-0",
          "8-4",
        ],
        initialValue: [],
      },
      Layout: `
			<div style='position: relative; font-size: 23px'>
			<div style='display:flex'>
				<img src="/img/FriendsPlus/Page15/E1/1.jpg" style='height:14cm;'  >
			</div>
			<div>
			<div style=" position: absolute; top: 73px; left: 39px" >I / You / We /<br> You / They<br>He / She / It</div>
			<div style=" position: absolute; top: 75px; left: 224px">has / <span style='padding: 5px; border: 2px solid gray; border-radius: 50%'>have</span><br><br><sup>2</sup><input id="1" type="Circle" /></div>
			<div style="position: absolute; top: 67px; left: 453px"><sup>1</sup><input id="0" type="Circle" /><br>an email.<br><sup>3</sup><input id="2" type="Circle" /> that film.</div>

			<div style=" position: absolute; top: 240px; left: 39px" >I / You / We /<br> You / They<br>He / She / It</div>
			<div style=" position: absolute; top: 240px; left: 224px"><sup>4</sup><input id="3" type="Circle" /><br><br><sup>6</sup><input id="5" type="Circle" /></div>
			<div style="position: absolute; top: 240px; left: 453px"><sup>5</sup><input id="4" type="Circle" /><br><br><sup>7</sup><input id="6" type="Circle" /></div>

			<div style=" position: absolute; top: 406px; left: 39px">She <sup>8</sup><input id="7" type="Circle" /> never played that game.<br>
			We <sup>9</sup><input id="8" type="Circle" /> ever heard that song.
			</div>

			</div>
					
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P15-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page15/Key/E2answerKey.png",
    isHiddenCheck: true,
    component: T6,
    inputSize: 230,
    maxLength: 35,
    titleQuestion: [
      {
        num: "2",
        title: ` Correct the words.`,
        color: "rgb(120,199,215)",
        star: 2,
      },
    ],
    questionImage: [],
    stylesTextInput: {
      background: "none",
      // border: "none",
      // borderBottom: "1px solid gray",
      fontSize: "22px",
      textAlign: "center",
    },
    questions: [
      {
        title: `
				<div style='font-size: 22px'>
				
        <div style='width: 900px; line-height: 40px'>
        My friend Melissa has recently <b><s>did&ensp;</s> <u style='color: gray'>&ensp;done&ensp;</u></b>
        a cookery course with her sister, and they 
        <b><sup>1</sup> has learned</b># how to 
        make different Spanish tapas. Melissa <b><sup>2</sup> has 
        want</b># to do this for 
        ages. She says it’s one of the best things 
        she <b><sup>3</sup> has never done</b># . I’m very 
        excited to try the new dishes she <b><sup>4</sup> made</b># 
        !<br>
        I think Melissa is feeling nervous, because she 
        <b><sup>5</sup> never cooked</b># for her friends before. 
        We <b><sup>6</sup> not tasted</b>#
         tapas dishes before, 
        but I’m sure they’ll be delicious! 
        
        </div>
					<img src="/img/FriendsPlus/Page15/E2/1.jpg" style='height:10cm;'  >
				</div>
				
        `,
        answer: [
          "have learned",
          "has wanted",
          "has ever done",
          "has made",
          "has never cooked",
          "haven't tasted",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P15-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page15/Key/E3answerKey.jpg",
    component: T6,
    inputSize: 510,
    textAlign: "left",
    titleQuestion: [
      {
        num: "3",
        title: `Write sentences using the present perfect.`,
        color: "rgb(120,199,215)",
        star: 2,
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div>
				<div>she / not taste Indian food</div>
				<div><strong>She hasn’t tasted Indian food.</strong></div>
				<div><strong style="margin-right:15px">1</strong>Lucy / buy a new perfume</div>
				<div>#</div>
				<div><strong style="margin-right:15px">2</strong>we / not listen to rap music</div>
				<div>#</div>
				<div><strong style="margin-right:15px">3</strong>you / eat a lot of cake</div>
				<div>#</div>
				<div><strong style="margin-right:15px">4</strong>I / hear that joke befor</div>
				<div>#</div>
				<div><strong style="margin-right:15px">5</strong>Henry / not watch the film</div>
				<div>#</div>
				<div><strong style="margin-right:15px">6</strong>they / not see the painting</div>
				<div>#</div>
			</div>
        `,
        answer: [
          "Lucy has bought a new perfume.",
          "We haven't listened to rap music.",
          "You've eaten a lot of cake.",
          "I've heard that joke before.",
          "Henry hasn't watched the film.",
          "They haven't seen the painting.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P15-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page15/Key/E4answerKey.png",
    component: Circle_Write,
    // inputSize: 110,
    textAlign: "center",
    titleQuestion: [
      {
        num: "4",
        title: `Choose the correct answers.`,
        color: "rgb(120,199,215)",
        star: 2,
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-0", "1-8", "2-8", "3-0", "4-0"],
        initialValue: [],
      },
      Layout: `
			<div>That school has been there for........</div>
			<div style="font-size:20px"><strong style="border:3px solid black;border-radius:50%;padding:5px">a</strong> forty years &emsp;<b>b</b> 1975&emsp;<b>c</b> I was born</div>
			<div style='display:flex;font-size:20px'>
		<div style='flex: 1'>
		  <b>1</b>&ensp;I’ve loved pasta since I ........ a child.
		  <div style="display: flex;">
			<div style="display: flex; margin-left: 40px">
			  <div><input id='0' type='Circle'/></div>
			  <div style='margin-left:20px'>
				was <br> have been <br> am
			  </div>
			</div>
		  </div>
	  
		  <div style ='flex:1;'>
			<b>2</b>&ensp; We’ve been in this flat .......... six months.   <br>
			<div style="display: flex;margin-left:40px">
			  <div><input id='1' type='Circle'/></div>
			  <div style='margin-left:20px'>
			  since <br> from <br> for
			  </div>
			</div>
	  
			<b>3</b>&ensp;........a chef for five years.  <br>
			<div style="display: flex;margin-left:40px">
			  <div><input id='2' type='Circle'/></div>
			  <div style='margin-left:20px'>
			  He’s being <br> He's <br> He's been
			  </div>
			</div>
	  
			<b>4</b>&ensp; Kemal has had no sense of smell for........
		  <div style="display: flex;margin-left:40px">
			<div><input id='3' type='Circle'/></div>
			<div style='margin-left:20px'>
			ages <br> she was born <br> last year
	  
			</div>
		  </div>
			<b>5</b>&ensp; We haven’t visited our grandparents since........
		  <div style="display: flex;margin-left:40px">
			<div><input id='4' type='Circle'/></div>
			<div style='margin-left:20px'>
				   last year <br> six months <br> a long time
	  
			</div>
		  </div>
	  
		  </div>
		</div>
	  
	
	  </div>
	  
	   
	  
			  
			`,
    },
  },
  5: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P15-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6,
    inputSize: 610,
    textAlign: "center",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `Write one or two sentences about these 
				things. Use <i>for</i> or <i>since</i> and the present perfect.`,
        color: "rgb(120,199,215)",
        star: 3,
      },
    ],
    questionImage: [],
    textareaStyle: {
      width: "800px",
      fontSize: 22,
    },
    questions: [
      {
        title: `
             <div style='font-size: 22px; line-height: 40px'>
				<div>where you live</div>
				<u style='color: gray'><strong>I’ve lived in Istanbul since I was four.<br>
				We’ve lived in our new house for a year.</strong></u>
				<div><strong style="margin-right:15px">1</strong>hobbies your brother, sister or friend has</div>
				<textarea id="0" rows="2"></textarea>
				<div><strong style="margin-right:15px">2</strong>your parents’ jobs</div>
				<textarea id="1" rows="2"></textarea>
				<div><strong style="margin-right:15px">3</strong>things you and your friends haven’t done for a while</div>
				<textarea id="2" rows="2"></textarea>
				<div><strong style="margin-right:15px">4</strong>places you haven’t been to for a while</div>
				<textarea id="3" rows="2"></textarea>
			 </div>
        `,
        answer: [``],
      },
    ],
  },
};

export default json;
