import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  // 1: {
  //   unit: "Unit 1",
  //   id: "FP8-WB-demo-2023-U1-P9-E1",
  //   audio: "",
  //   video: "",
  //   // recorder: true,
  //   exerciseKey: "/img/FriendsPlus/Page9/Key/E1answerKey.jpg",
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `
  // 		Choose the correct words to complete
  // 		the table.
  // 				`,
  //       color: "rgb(28,191,221)",
  //       star: 1,
  //     },
  //   ],

  //   component: DesignUnderLine,
  //   totalInput: 7,
  //   titleImage: "",
  //   isHiddenCheck: true,

  //   isAllowSubmit: false,
  //   questionImage: [
  //     // Row
  //     [{ url: "img/FriendsPlus/Page9/E1/1.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/2.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/3.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page9/E1/4.jpg", input: 1, isCorrect: true },
  //       { url: "img/FriendsPlus/Page9/E1/5.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page9/E1/6.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/7.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/8.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page9/E1/9.jpg" },
  //     ],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/10.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/11.jpg", input: 2, isCorrect: true },
  //       { url: "img/FriendsPlus/Page9/E1/12.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page9/E1/13.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/14.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/15.jpg", input: 3, isCorrect: true },
  //       { url: "img/FriendsPlus/Page9/E1/16.jpg", input: 3 },
  //       { url: "img/FriendsPlus/Page9/E1/17.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/18.jpg", input: 4, isCorrect: true },
  //       { url: "img/FriendsPlus/Page9/E1/19.jpg", input: 4 },
  //       { url: "img/FriendsPlus/Page9/E1/20.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/21.jpg", input: 5 },
  //       { url: "img/FriendsPlus/Page9/E1/22.jpg" },
  //     ],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/23.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/24.jpg", input: 5, isCorrect: true },
  //     ],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/25.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/26.jpg", input: 6, isCorrect: true },
  //       { url: "img/FriendsPlus/Page9/E1/27.jpg" },
  //     ],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/28.jpg" },

  //       { url: "img/FriendsPlus/Page9/E1/29.jpg", input: 6 },
  //       { url: "img/FriendsPlus/Page9/E1/30.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page9/E1/31.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page9/E1/32.jpg" },
  //       { url: "img/FriendsPlus/Page9/E1/33.jpg", input: 7, isCorrect: true },
  //       { url: "img/FriendsPlus/Page9/E1/34.jpg", input: 7 },
  //       { url: "img/FriendsPlus/Page9/E1/35.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page9/E1/36.jpg" }],
  //   ],
  // },
  1: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P9-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page9/Key/E1answerKey.png",
    component: Circle_Write,
    textAlign: "center",
    // maxLength: 1,
    inputSize: 80,
    titleQuestion: [
      {
        num: "1",
        title: `
    	Choose the correct words to complete 
    	the table.
    			`,
        color: "rgb(28,191,221)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,

    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          // borderColor: "transparent",
          fontWeight: 700,
          // color: "rgb(88,147,198)",
        },
        // initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: {
          padding: 5,
          border: "2px solid black",
          borderRadius: "50%",
          // borderColor: "",
        },
        limitSelect: 1,
        listWords: [
          "lived / live",
          "didn’t_used_to /<br> didn’t_use_to",
          "Did / were",
          "use / used",
          "played / did",
          "didn’t / wasn’t",
          "use / used",
        ],
        answers: ["2-0", "0-4", "1-4", "3-0", "4-4", "5-0", "6-0"],
        initialValue: [],
      },
      Layout: /*html */ `
      <div style='position: relative'>
      <img style="width:21cm; height:17cm" src='img/FriendsPlus/Page9/E1/1.jpg' />
        <div style=" position: absolute; top: 72px; left: 32px; ">I / You / He / She /<br> It / We /<br> You / They</div>
        <div style=" position: absolute; top: 79px; left: 252px; ">use / <span style='border: 2px solid gray; padding: 5px; border-radius: 50%'>used</span>to</div>
        <div style=" position: absolute; top: 79px; left: 488px; "><sup>1</sup><input id="0" type="Circle" /> here.</div>

        <div style=" position: absolute; top: 232px; left: 32px; ">I / You / He / She /<br> It / We /<br> You / They</div>
        <div style=" position: absolute; top: 239px; left: 252px; "><sup>2</sup><input id="1" type="Circle" /></div>
        <div style=" position: absolute; top: 232px; left: 488px; ">drink tea.</div>

        <div style=" position: absolute; top: 393px; left: 32px; "><sup>3</sup><input id="2" type="Circle" /> you</div>
        <div style=" position: absolute; top: 393px; left: 252px; "><sup>4</sup><input id="3" type="Circle" /><br> to play tennis?</div>
        <div style=" position: absolute; top: 393px; left: 488px; ">Yes, I <sup>5</sup><input id="4" type="Circle" />.</div>

        <div style=" position: absolute; top: 439px; left: 488px; ">No, I <sup>6</sup><input id="5" type="Circle" />.</div>

        <div style=" position: absolute; top: 525px; left: 32px; ">Where did he</div>
        <div style=" position: absolute; top: 525px; left: 252px; "><sup>7</sup><input id="6" type="Circle" /><br> to live?</div>
        <div style=" position: absolute; top: 525px; left: 488px; ">With his parents.</div>
      </div>

      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P9-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    inputSize: 540,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "2",
        title: `Rewrite four of the sentences using the 
			  correct form of used to. Tick (✓) the sentence that 
			  can only be in the past simple.`,
        color: "#234090",
        star: 2,
      },
    ],
    stylesTextInput: {
      background: "none",
      fontSize: 22,
      height: 27,
    },

    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 37,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", " "],
    questionImage: [],
    questions: [
      {
        title: `
			<img style='margin-left:100px; height:28mm;margin-bottom:10px' src="img/FriendsPlus/Page9/E2/1.jpg"/>
			
			<div style='position: relative; font-size: 22px'>
				
					<div style='display:flex'>
						<b>1</b>&ensp;Did you often visit your grandparents? <select id=5></select>
					</div>
						#
					<div style='display:flex'>
						<b>2</b>&ensp;We went to France on holiday last year.<select id=6></select>
					</div>
						#
					<div style='display:flex'>
						<b>3</b>&ensp;My sister loved One Direction!<select id=7></select>
					</div>
							#
				<div style=" position: absolute; top: 0px; left:587px; width:15cm ">						
					<div style='display:flex'>
						<b>4</b>&ensp;He didn’t have many gadgets. <select id=8></select>
					</div>
						#
					<div style='display:flex'>
						<b>5</b>&ensp;Did you like going to the beach?<select id=9></select>
					</div>
						#
				</div>
			</div>
			
			`,
        answer: [
          "Did you often use to visit your grandparents?",
          "",
          "My sister used to love One Direction!",
          "He didn’t use to have many gadgets.",
          "Did you use to like going to the beach?",
          " ",
          "✓",
          " ",
          " ",
          " ",
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P9-E3",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 600,
    maxLength: 50,
    exerciseKey: "/img/FriendsPlus/Page9/Key/E3answerKey.png",
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Correct the words.
				`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='line-height: 45px'>
						<div>We <span style="font-weight:bold;text-decoration:line-through">weren’t use</span> to go to that school. <u style="margin-left:20px;font-weight:bold; color: gray">&ensp;didn’t use&ensp;</u></div>
						<div><strong style="margin-right:20px">1</strong> They <b>didn’t used</b> to like computer games.</div>
						<div>#</div>
						<div><strong style="margin-right:20px">2</strong><b>Did they used</b> to buy Marvel comics?</div>
						<div>#</div>
						<div><strong style="margin-right:20px">3</strong>My sister <b>used to loved</b> Beanie Babies. </div>
						<div>#</div>
						<div><strong style="margin-right:20px">4</strong>What did your favourite cartoon <b>use be?</b></div>
						<div>#</div>
						<div><strong style="margin-right:20px">5</strong>  I <b>used to not</b> have a games console. </div>
						<div>#</div>
						<div><strong style="margin-right:20px">6</strong> ‘Did you use to collect Pokémon cards?’ ‘Yes, I <b>used</b>.’</div>
						<div>#</div>
					</div>
        `,
        answer: [
          "didn't use",
          "Did they use",
          "used to love",
          "use to be",
          "didn't use to",
          "did",
        ],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P9-E3",
    exerciseKey: "",
    component: T6v2,
    hideBtnFooter: true,
    textareaStyle: { width: 800 },
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Write sentences to compare you and 
				your family in the past and present. Use used to
				and the present simple. Write three or four 
				sentences for each topic.				`,
        color: "rgb(28, 192, 217)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
						<div><b>When I was younger, I used to listen to music on an iPod.
						Now I use my smartphone or tablet.</b></div>
						<div><strong style="margin-right:20px">1</strong><strong>Gadgets</strong></div>
						<textarea id="0" rows="4"></textarea>
						<div><strong style="margin-right:20px">2</strong><strong>Toys</strong></div>
						<textarea id="1" rows="4"></textarea>

					</div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
