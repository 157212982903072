import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P37-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page37/key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
			Study the example. Then match 1–6 with a–f and write the complete sentences, using to-infinitive to express purposes. 
				  `,
        color: "#02A94F",
        star: 1,
      },
    ],
    stylesTextInput: { borderBottom: "10px dashed", textAlign: "center" },
    // inputSize: 80,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "500px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "500px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "500px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "225px",
              left: "500px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "275px",
              left: "500px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "500px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // A
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "750px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "750px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // C
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "750px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "225px",
              left: "750px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // E
          {
            boxMatchStyle: {
              position: "absolute",
              top: "275px",
              left: "750px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          // F
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "750px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
        ],
        answers: ["0-8", "1-11", "10-2", "3-7", "4-6", "5-9"],
        initialValue: [],
      },
      Layout: `
		  <div style='font-size:22px;font-weight:bold'>
		 <i> The mother left her office early to go home with her child.</i> </div>
			  <div style='margin-top:10px;display: flex; justify-content: space-around; width:1250px; font-size: 26px; line-height:2'>
				<div style='flex:1' >
					<div><b> 1  </b>&ensp;I took the train to town </div>
					<div><b> 2  </b>&ensp;They are going to the café </div>
					<div><b> 3  </b>&ensp;There will be some costumes for you </div>
					<div><b> 4  </b>&ensp;She volunteered at the soup kitchen </div>
					<div><b> 5  </b>&ensp;My father works two jobs</div>
					<div><b> 6  </b>&ensp;Jeffrey ran outside quickly
					</div>
				</div>    
				<div style='flex:1;margin-left:320px' >
				  <div style='display: flex;'>
					<div style='margin-right: 10px;'><b> A </b></div>
					<div>  support the family.
					</div>
				  </div>
				  <div style='display: flex;'>
					<div style='margin-right: 10px;'><b> B </b></div>
					<div> help the poor. </div>
				  </div>
				  <div style='display: flex;'>
					<div style='margin-right: 10px;'><b> C </b></div>
					<div> visit my uncle and aunt. </div>
				  </div>
				  <div style='display: flex;'>
					<div style='margin-right: 10px;'><b> D </b></div>
					<div>  see the parade.  </div>
				  </div>
				  <div style='display: flex;'>
					<div style='margin-right: 10px;'><b> E </b></div>
					<div>  wear at the prom next week. </div>
				  </div>
				  <div style='display: flex;'>
					<div style='margin-right: 10px;'><b> F </b></div>
					<div> grab some hot espresso. </div>
				  </div>
				 
				</div>     
			  </div>     
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
			  <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
			  <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
			  <input id='8' type= 'boxMatch' />
			  <input id='9' type= 'boxMatch' />
			  <input id='10' type= 'boxMatch' />
			  <input id='11' type= 'boxMatch' />
		  `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P37-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page37/key/E2answerKey.png",
    component: T6,
    isHiddenCheck: true,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "22px",
    },
    inputSize: 850,
    // maxLength: 1,
    titleQuestion: [
      {
        num: "2",
        title: `
				Combine two sentences using <i>to</i>.
				`,
        color: "rgb(0, 169, 79)",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
				
				<div style='display:flex; '>
					
					<span style=' font-size: 22px; line-height:60px'>
					<b>1</b> My son brushed his teeth at 9.00 p.m. He wanted to go to bed early.
					</span>
					</div>
					<input/> 
					</div>

					<div style='display:flex; font-size: 22px; line-height:60px'>
					<b>2</b>
					<div style='margin-left:10px'>
					Emily is borrowing some books from the library. She needs to do some research.					</div>
					</div>
					<input/> 
					</div>

					<div style='display:flex;font-size: 22px; line-height:60px'>
					<b>3</b>
					<div style='margin-left:10px'>
					Denis buys a dozen eggs. He’s going to make a cake for his wife.
					</div>

					</div>
					<input/> 
					</div>

					<div style='display:flex; font-size: 22px; line-height:60px'>
					<b>4</b>
					<div style='margin-left:10px'>
					We should drink a lot of water. It’s necessary to prevent dehydration. 
					</div>
					</div>
					<input/> 
					</div>

					<div style='display:flex; font-size: 22px; line-height:60px'>
					<b>5</b>
					<div style='margin-left:10px'>
					We left home early yesterday. We could avoid traffic jams at rush hours.  
					</div>
					</div>
					<input/> 
					</div>
				
        `,
        answer: [
          `My son brushed his teeth at 9.00 p.m. to go to bed early.`,
          `Emily is borrowing some books from the library to do some research.`,
          `Denis buys a dozen eggs to make a cake for his wife.`,
          `We should drink a lot of water to prevent dehydration.`,
          `We left home early yesterday to avoid traffic jams at rush hours.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P37-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page37/key/E3answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "none",
      // border: "none",
      textAlign: "center",
      fontSize: "20px",
    },
    hintBox: [
      {
        src: [
          `enable`,
          `<s>growth</s>`,
          `rapidly`,
          `in`,
          `races`,
          `ourselves`,
          `together`,
        ],
        borderColor: "rgb(0, 169, 79)",
        width: 800,
      },
    ],

    isHiddenCheck: true,
    inputSize: 130,
    maxLength: 9,
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete Tony’s blog about the future life with the correct words in the box.
				`,
        color: "rgb(0, 169, 79)",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
					<div style='margin-top: 10px; display: flex; gap: 20px;'>
							<div>
							<hintBox id='0'></hintBox></div>
					</div>


					<div style='position: relative;;margin-top:10px;'>
					<div style='position: absolute; top: 155px; left: 135px; '>

					<div style ='width:860px; font-size:20px; line-height:40px'>
					If there is not enough room on Earth for everyone, what will happen? <br> <br>
					The planet will someday become congested due to the tremendous rate of <br> population growth .
					 To survive, we should locate a better home on another world. <br> 
					 It is conceivable that humans will create technologies that will 
					 <sup>1</sup> <input/> <br> us to survive on planets like Mars, which have been identified in the solar system.
					  Additionally, we can continue to explore the Milky Way <sup>2</sup> <input/> hope of a <br> planet that resembles our own. 
					  With our ability, we can exploit the planet <br> efficiently, as well
					   as combining what we have on Earth to <sup>3</sup> <input/> <br> shorten that process. If this happens,
					    we may see a very diverse community <br> living <sup>4</sup> <input/> on  a new planet, where people from 
						different <sup>5</sup> <input/> <br> giving their hand to create a new society on a ‘second home’. Besides,
						 we can <br> also give <sup>6</sup> <input/>opportunities to study the new world, explore a new <br> ecosystem,
						 build shelters and live healthily. 

					</div>

					</div>
									
					<img src='img/FriendsPlus/Page37/E3/1.jpg' style='max-width:85%'>

					</div>
		 
		 
        `,
        answer: [`enable`, `in`, `rapidly`, `together`, `races`, `ourselves`],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P37-E4",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page37/E4/Key/answerKey.png",
    component: T6v2,
    // stylesTextInput: {
    // 	textAlign: 'center',
    // },
    hideBtnFooter: true,
    isHiddenCheck: true,
    inputSize: 100,
    // maxLength: 1,
    titleQuestion: [
      {
        num: "4",
        title: `
				Imagine you are planning to move to a new planet. Write a blog about what you will be doing to prepare for your journey. Include information about the following things: 
				`,
        color: "rgb(0, 169, 79)",
        star: 3,
      },
    ],
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
				<div style='display:flex'>
				<div style='flex:1;margin-left:30px'>
				<ul>
				<li>what your general plans are</li>
				<li>where you will be living</li>
				<li>what you will do to survive there</li>
				<li> what your expectation about the new planet</li>
				<li>how you will cope with the difficulties</li>

				</ul>
				<div style='width:600px'>
				Use the blog in exercise 3 to help you. Include
to-infinitive, future simple, future continuous and
the phrases on this page.
				</div>
				</div>
				<div style='flex:1'>
				<textarea id='0' rows ='10'></textarea>
				</div>
				
				</div>
        `,
        answer: [`where`, `who`, `which`, `which`, `who`],
      },
    ],
  },
};

export default json;
