import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P14-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page14/Key/E1answerKey.jpg",
    // checkDulicated:true,
    component: T6,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      borderBottom: "1px solid gray",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.`,
        color: "rgb(1,103,180)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='margin-top: 10px; display: flex; flex-direction: column; gap: 10px; align-items: center;'>
            <div style='display: flex; flex-wrap: wrap; gap: 20px; align-items: center; justify-content: center;
              max-width: 560px; padding: 10px 30px; border-radius: 20px; border: 3px solid rgb(1,103,180);'>
              <span>feel</span><span>hearing</span><span style=''>hold</span><span style=''>listen to</span><span style=''>look at&emsp;</span>
              <div><br></div>
							<span>see</span><span>sight</span><span>smell(x2)</span>
              <span style="text-decoration: line-through">taste (x2)</span><span>touch (x2)</span>
            </div>

            <div style='position: relative;'>
              <div><img src='img/FriendsPlus/Page14/E1/1.jpg' width='100%' /></div>
              <div style='position: absolute; top: 152px; left: 256px;'>#</div>
              <div style='position: absolute; top: 152px; left: 480px;'>#</div>
              <div style='position: absolute; top: 188px; left: 480px;'>#</div>
              <div style='position: absolute; top: 224px; left: 480px;'>#</div>
              <div style='position: absolute; top: 280px; left: 256px;'>#</div>

              <div style='position: absolute; top: 280px; left: 480px;'>#</div>
              <div style='position: absolute; top: 349px; left: 256px;'>#</div>
              <div style='position: absolute; top: 349px; left: 480px;'>#</div>
              <div style='position: absolute; top: 416px; left: 256px;'>#</div>
              <div style='position: absolute; top: 416px; left: 480px;'>#</div>
              <div style='position: absolute; top: 453px; left: 480px;'>#</div>

							
							
            </div>
          </div>
        `,
        answer: [
          "touch",
          "touch / feel / hold",
          "touch / feel / hold",
          "touch / feel / hold",
          "hearing",
          "listen to",
          "smell",
          "smell",
          "sight",
          "see / look at",
          "see / look at",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P14-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page14/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 10,
    inputSize: 160,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the café review with the words.`,
        color: "rgb(1,103,180)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px; max-width:690px; margin-bottom:20px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(1,103,180);'>
          <div><span style="margin-right:20px">asked</span><span style="margin-right:20px;">feels</span><span style="margin-right:20px">hear</span><span style="margin-right:20px">listen</span><span style="margin-right:20px">looks</span><span style="margin-right:20px;">see</span><span style="margin-right:20px;">senses</span><span style="margin-right:20px;"><s>tastes</s></span></div>

        </div>
        
      </div>

        <div style='position: relative; font-size: 20px'>
          <div><img src='img/FriendsPlus/Page14/E2/1.jpg' style='width: 17cm; height: 23cm' /></div>
          <div style='position: absolute; top: 440px; left: 38px; width: 567px; line-height: 30px'>
          What makes a café an exciting place to visit? We asked 
          café manager Richard Moore. ‘Our food <u style='color: gray'>&ensp;tastes&ensp;</u>
          great when you eat it, but it’s important that the café 
          <sup>1</sup># attractive, too.<br>
          People use all of their <sup>2</sup># when they eat 
          out,’ he says. ‘They <sup>3</sup># the paintings on the 
          walls and <sup>4</sup># the music we’re playing long 
          before they eat our food. We <sup>5</sup># an artist to 
          choose the pictures and the furniture, and a local DJ to 
          recommend the right music for people to <sup>6</sup>#
          to while they eat.’ <br>
          The results are great! Richard’s café <sup>7</sup>#
          exciting and different. I really enjoyed my visit.
          </div>
        </div>
      </div>
        `,
        answer: ["looks", "senses", "see", "hear", "asked", "listen", "feels"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P14-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "/img/FriendsPlus/Page14/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct words.`,
        color: "rgb(1,103,180)",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: { fontSize: 22 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "2px solid rgb(0, 173, 238)",
          padding: "5px",
        },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "colour-blindness / tone-deafness / a_‘good_ear",
          "touch / smell / sight",
          "listen / ask / watch",
          "feels / looks / tastes",
          "sight / sound / hearing",
          " sensation / touch / smell ",
          "hear / listen / sound",
          "See / Watch / Look_at",
        ],
        answers: ["0-4", "1-4", "2-4", "3-8", "4-4", "5-2", "6-0", "7-8"],
        initialValue: [],
      },
      Layout: `
			  <div style='line-height:45px; font-size: 22px'>
          <div>Don’t <strong><span style="border: 3px solid black;border-radius:50%;padding:5px">touch</span> / feel / sound</strong> that pan. It’s hot!</div>
          <div><strong style="margin-right:15px">1</strong>He can’t sing well. He has got <input id="0" type="Circle"> The Beatles, more and 
          more teenagers started to like pop.</div>
          <div><strong style="margin-right:15px">2</strong>To be a good cook, you need a strong sense of <input id="1" type="Circle">.</div>
          <div><strong style="margin-right:15px">3</strong>I need to <input id="2" type="Circle"> my mum for help 
          with my homework.</div>
          <div><strong style="margin-right:15px">4</strong>There’s a lot of salt in this soup. It <input id="3" type="Circle"> terrible!</div>
          <div><strong style="margin-right:15px">5</strong>Listen to the <input id="4" type="Circle">  of the 
          birds singing.</div>
          <div><strong style="margin-right:15px">6</strong>It’s very cold. I’m losing <input id="5" type="Circle">  in my toes.</div>
          <div><strong style="margin-right:15px">7</strong>It’s noisy outside. You can <input id="6" type="Circle"> cars and people in the street.</div>
          <div><strong style="margin-right:15px">8</strong><input id="7" type="Circle"> our holiday photos.</div>
			  </div>
			  `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P14-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 900,
    hideBtnFooter: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      borderBottom: "1px solid gray",
      fontSize: "20px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete 
        sentences that are true for you.
				`,
        color: "rgb(1,103,180)",
        star: 3,
      },
    ],
    textareaStyle: {
      width: "800px",
    },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
            <div>Which tastes are typical of the traditional food in 
            your region?</div>
            <div><strong>Sweet fruit cakes, salty seafood and fresh water are
            tastes from Wales.</strong></div>
            <div><strong style="margin-right:15px">1</strong>Think of a normal day. How and when would 
            colour-blindness be a problem?</div>
            <textarea id="0" rows="2"></textarea>

            <div><strong style="margin-right:15px">2</strong>Are you tone-deaf, or have you got a ‘good ear’ 
            for music? Explain your answer.</div>
            <textarea id="3" rows="2"></textarea>

            <div><strong style="margin-right:15px">3</strong>Which sounds and smells remind you of</div>
            <div style="margin-left:20px"><strong style="margin-right:15px">a</strong> your childhood?</div>
            <textarea id="1" rows="2"></textarea>
            <div style="margin-left:20px"><strong style="margin-right:15px">b</strong>summertime?</div>
            <textarea id="2" rows="2"></textarea>

            <div><strong style="margin-right:15px">4</strong>Who in your family do you look like and how?</div>
            <textarea id="4" rows="2"></textarea>
          </div>
        `,
        answer: [""],
        // answer: ['Photo B'],
      },
    ],
  },
};

export default json;
