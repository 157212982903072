import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P4-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page4/Key/E1answerKey.png",
    component: T6v2,
    isHiddenCheck: true,
    stylesTextInput: {},
    inputSize: 500,
    titleQuestion: [
      {
        num: "1",
        title:
          "Match 1–7 with a–g. Then use the time expressions to say how often you do each activity.",
        color: "rgb(3, 103, 181)",
        star: 1,
      },
    ],

    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:4cm' src="img/FriendsPlus/Page4/E1/1.jpg"/>
        <div style='display:flex;justify-content:space-between;line-height:55px;width:27cm'>
          <div >
            <b>1 </b>&ensp;tidy <br>
            <b>2 </b>&ensp;do <br>
            <b>3 </b>&ensp;watch <br>
            <b>4 </b>&ensp;go <br>
            <b>5 </b>&ensp;listen <br>
            <b>6 </b>&ensp;have <br>
            <b>7 </b>&ensp;wake
          </div>
          <div>
            <input id='0'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>a </b>&ensp;to music<br>
            <input id='1'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>b </b>&ensp;breakfast<br>
            <input id='2'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>c </b>&ensp;your room<br>
            <input id='3'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>d </b>&ensp;your homework<br>
            <input id='4'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>e </b>&ensp;up<br>
            <input id='5'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>f </b>&ensp;videos<br>
            <input id='6'   border='solid 2px black' border-radius="5px" width="40px" height="40px"></input>&ensp;<b>g </b>&ensp;to school
          </div>
          <div  >
            <b>1</b>&ensp;<u style='color: gray'>&ensp;I tidy my room once a week.&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u><br>
            <b>2</b>&ensp;#<br>
            <b>3</b>&ensp;#<br>
            <b>4</b>&ensp;#<br>
            <b>5</b>&ensp;#<br>
            <b>6</b>&ensp;#<br>
            <b>7</b>&ensp;#
          </div>
          
        </div>
          
        `,
        answer: ["5", "6", "1", "2", "7", "3", "4", "", "", "", "", "", ""],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P4-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page4/Key/E2answerKey.jpg",
    component: T6,
    // maxLength: 1,
    fontSize: 30,
    inputSize: 630,
    titleQuestion: [
      {
        num: "2",
        title: `Correct the words.`,
        color: "rgb(3, 103, 181)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div>
            <div>I finish school at 3.00 and I usually <span style="font-weight:bold;text-decoration:line-through">get to home</span> at 
            3.45. <strong style="margin-left:20px">get home</strong></div>
            <div><strong style="margin-right:15px">1</strong>What time do you usually <b>wake you up</b>?</div>
            <div>#</div>
            <div><strong style="margin-right:15px">2</strong><b>We relax always</b> after dinner.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">3</strong>They go shopping <b>in the weekend</b>.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">4</strong>Do you always <b>make your homework</b>?</div>
            <div>#</div>
            <div><strong style="margin-right:15px">5</strong>My brother <b>doesn’t listen music</b> much, but he 
            watches videos every day.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">6</strong>My mum goes to work at eight <b>on the morning</b>.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">7</strong>On Saturdays I often <b>have the breakfast</b> late.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">8</strong>We go to bed later <b>the Fridays</b>.</div>
            <div>#</div>
          </div>
        `,
        answer: [
          " wake up",
          "We always relax",
          "at the weekend",
          "do your homework",
          "doesn't listen to music",
          "in the morning",
          "have breakfast",
          "on Fridays",
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P4-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page4/Key/E3answerKey.jpg",
    component: T6,
    // maxLength: 1,
    // fontSize: 25,
    textAlign: "center",
    inputSize: 250,
    titleQuestion: [
      {
        num: "3",
        title: `Complete the sentences with the 
        correct verbs.`,
        color: "rgb(3, 103, 181)",
        star: 2,
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: 'uppercase',
    },
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='font-size: 23px'>
            <div>Mum and Dad always <b>cook</b>
            on weekdays, but sometimes I 
            do it at the weekend. I normally 
            make pasta and salad for dinner.</div>
            <div><strong style="margin-right:15px">1</strong>I don’t usually # immediately after waking up. 
            I like to stay in bed for a few minutes and think 
            about my day.</div>
            <div><strong style="margin-right:15px">2</strong>I always # before school. I often 
            have juice, some toast and some fruit. Then I’m 
            not hungry until lunchtime.</div>
            <div><strong style="margin-right:15px">3</strong>I usually # as soon as I get home 
            from school. When it’s finished, I can enjoy the 
            rest of my evening.</div>
            <div><strong style="margin-right:15px">4</strong>I try to # for a 
            while every day. Sometimes I 
            go for a walk, and other times I 
            watch videos or listen to music.</div>
            <div><strong style="margin-right:15px">5</strong>I don’t # very often 
            because I haven’t got much money to spend. I 
            sometimes go running with my friends instead. 
            We like exercising.</div>
            <div><strong style="margin-right:15px">6</strong>I # every morning by bus. It takes 
            about twenty-five minutes. The bus arrives at 
            8.05, and lessons start at 9.00, so I’m never late.</div>
            
          </div>
        `,
        answer: [
          " get up",
          " have breakfast",
          "do my homework",
          "relax",
          "go shopping",
          "go to school",
        ],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P4-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 1,
    fontSize: 30,
    hideBtnFooter: true,
    textareaStyle: { width: "900px" },
    inputSize: 630,
    titleQuestion: [
      {
        num: "4",
        title: `Write four or five complete sentences 
        comparing your typical daily routine with what 
        you do on holiday. What’s the same and what’s 
        different?`,
        color: "rgb(3, 103, 181)",
        star: 3,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div>
           <div><strong>I always wake up at 6.30 on weekdays and at around
           8.30 at the weekend. When I’m on holiday, I usually 
           get up later ...</strong></div>
           <div><textarea id="0" rows="6"></textarea></div>
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
