import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 10,
    stylesTextInput: {
      fontSize: "20px",
      background: "none",
      borderBottom: "none",
    },
    hintBox: [
      {
        src: [
          "<del>desert</del>",
          "dunes",
          "falls",
          "mountain",
          "rocks",
          "sea",
        ],
        borderColor: "rgb(0,103, 178)",
        width: 650,
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: `
				Label the pictures with the words.
				`,
        color: "rgb(0,103, 178)",
        star: 1,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<center><hintBox id='0'></hintBox></center>
          <div style="display:flex; padding-top:20px; position: relative">
            <img src='img/FriendsPlus/Page20/E1/1.jpg' width='100%' style="padding:20px"/>
            <img src='img/FriendsPlus/Page20/E1/2.jpg' width='100%' style="padding:20px"/>
            <div style=" position: absolute; width: 140px; top: 76px; left: 89px;  "><input id='0'></div>
          <div style=" position: absolute; width: 140px; top: 100px; left: 344px; "><input id='1'></div>
          <div style=" position: absolute; width: 140px; top: 70px; left: 636px; "><input id='2'></div>
          <div style=" position: absolute; width: 140px; top: 70px; left: 871px; "><input id='4'></div>
          <div style=" position: absolute; width: 140px; top: 237px; left: 884px; "><input id='5'></div>
            </div>
          
				`,
        answer: ["dunes", "rocks", "falls", "mountain", "sea"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 10,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "21px",
      background: "none",
      height: 25,
      // borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the words in the text.
				`,
        color: "rgb(0,103, 178)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative; font-size: 20px'>
        <img src='img/FriendsPlus/Page20/E2/1.jpg' style="padding:2px; width: 17cm"/>
        <div style=" position: absolute; width: 520px; top: 53px; left: 56px; line-height: 38px">
        Cliffs form where the land ends high above the 
        water. In Arctic regions they are made of ice. 
        <sup>1</sup><b>o#</b> have salt water in them and cover 
        nearly 70% of the Earth. <sup>2</sup><b>s#</b> are also 
        salty, but they are smaller. Usually they have land 
        around most of their area.<br>
        <sup>3</sup><b>D#</b> are hills made of sand. The wind 
        usually forms them behind sandy <sup>4</sup><b>b#</b>
        by the sea, or in <sup>5</sup><b>d#</b> like the Sahara.
        In some places, water from <sup>6</sup><b>r#</b> travels 
        over rock. Over millions of years, it cuts the rock 
        away. This can result in large <br><sup>7</sup><b>v#</b> , such 
        as the Hudson in the USA. Underground, moving 
        water also creates <sup>8</sup><b>c#</b> like those in 
        Jeita, Lebanon. 
        </div>

        </div>
       
				`,
        answer: [
          "ceans",
          "eas",
          "unes",
          "eaches",
          "eserts",
          "ivers",
          "alleys",
          "aves",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/Key/E3answerKey.png",
    component: Circle_Write,
    inputSize: 180,

    titleQuestion: [
      {
        num: "3",
        title: `
        Cross out the incorrect word.
				`,
        color: "rgb(0,103, 178)",
        star: 2,
      },
    ],
    isHiddenCheck: false,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          //fontStyle: "italic",
          color: "",
          fontSize: 21,
          border: "none",
          // borderRadius: "0",
          // marginInline: '-1px',
        },
        selectWordStyle: { borderBottom: "2px solid" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `volcano / valley / rainforest`,
          `cliffs / the_ocean / a_rock`,
          `waves / deserts /<br> dunes`,
          `the_sea / the_ocean / a_mountain`,
          `falls / a_desert /<br> a_lake`,
        ],
        answers: ["0-0", "1-4", "2-0", "3-8", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative'>
          <img src='img/FriendsPlus/Page20/E3/1.jpg' style="padding:2px"/>
				<div style='top:430px; left:15px ; position:absolute;'>
            <span style="font-size:21px"><b>&#8226;</b> swim across <b>lakes / <del>beaches</del> / rivers</b> </span>
        </div>

        <div style='top:460px; left:15px ; position:absolute;'>
            <span style="font-size:21px"><b>&#8226;</b> cycle through an enormous <br> </span>
            <b><sup>1 </sup></b><input id='0' type='Circle' />
        </div>

        <div style='top:530px; left:15px ; position:absolute;'>
            <span style="font-size:21px"><b>&#8226;</b> climb </span>
            <b><sup>2 </sup></b><input id='1' type='Circle' />
        </div>

        <div style='top:570px; left:15px ; position:absolute;'>
            <span style="font-size:21px"><b>&#8226;</b> run over sandy </span>
            <b><sup>3 </sup></b><input id='2' type='Circle' />
        </div>

        <div style='top:640px; left:15px ; position:absolute;'>
            <span style="font-size:21px"><b>&#8226;</b> swim five kilometres out in<br> </span>
            <b><sup>4 </sup></b><input id='3' type='Circle' />
        </div>

        <div style='top:710px; left:15px ; position:absolute;'>
            <span style="font-size:21px"><b>&#8226;</b> sail around </span>
            <b><sup>5 </sup></b><input id='4' type='Circle' />
        </div>

				</div>
			  `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P20-E4",
    audio: "",
    video: "",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 280,
    textareaStyle: { width: 930, height: 130 },
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `

        Answer the questions. Give reasons for your answers.
				`,
        color: "rgb(0,103, 178)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>Which natural features do you think … ?</div>
          
          <div>
            <div><b>1</b> are the most beautiful</div>
            <u style='color: gray'><i>I think lakes and rivers are the most beautiful because the calm, blue water is relaxing.</i></u>
          </div>

          <div>
            <div><b>2</b> are the most dangerous</div>
            <textarea id="0" rows="2"></textarea>
          </div>

          <div>
            <div><b>3</b> are the best to visit</div>
            <textarea id="1" rows="2"></textarea>
          </div>

          <div>
            <div><b>4</b> have the most interesting wildlife</div>
            <textarea id="2" rows="2"></textarea>
          </div>

				`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-SB-demo-2023-US-P8-E5",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: [
          "I love the smell of banh xeo. It really reminds me of my grandma’s house. It brings back memories of visiting her house when I was little.",
        ],
        borderColor: "rgb(219, 46, 57)",
        arrow: true,
        position: 1,
        width: 600,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
					Talk about which objects and places smell best to you. Which of them bring back memories?
				`,
        color: "rgb(219, 46, 57)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintbox id='0'></hintbox>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
