import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P21-E1",
    exerciseKey: "img/FriendsPlus/Page21/Key/E1answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: [
          "been",
          "has",
          "hasn't",
          "have",
          "haven't",
          "<del>we</del>",
          "working",
        ],
        borderColor: "rgb(0,103, 178)",
        width: 720,
      },
    ],
    inputSize: 110,
    maxLegth: 10,
    stylesTextInput: {
      fontSize: "21px",
      textAlign: "center",
      background: "none",
      // borderBottom: "none",
      height: 25,
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the table with the words.
				`,
        color: "rgb(0,103, 178)",
        star: 1,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<center><hintBox id='0'></hintBox></center>
					<div style='position: relative; margin-top: 30px; font-size: 21px'>
					<center><img src='img/FriendsPlus/Page21/E1/1.jpg' style='width: 17cm;' /></center>

					<div style=" position: absolute; width: 150px; top: 69px; left: 69px; "><b>Affirmative</b><br>I / You / <u style='color: gray'> We </u> /<br>You / They<br>He / She / It</div>
					<div style=" position: absolute; width: 150px; top: 98px; left: 290px; "><sup>1</sup>#<br><br><sup>2</sup>#</div>
					<div style=" position: absolute; width: 150px; top: 98px; left: 478px; "><sup>3</sup>#<br>sleeping.</div>

					<div style=" position: absolute; width: 150px; top: 232px; left: 69px; "><b>Negative</b><br>I / You / We /<br>You / They<br>He / She / It</div>
					<div style=" position: absolute; width: 150px; top: 265px; left: 290px; "><sup>4</sup>#<br><br> hasn’t</div>
					<div style=" position: absolute; width: 150px; top: 265px; left: 478px; ">been<br><sup>5</sup>#</div>

					<div style=" position: absolute; top: 385px; left: 69px; "><b>Questions and short answers</b><br>Have they been listening?<br>Has she been reading?</div>
					<div style=" position: absolute; top: 417px; left: 478px; ">Yes, they have.<br>No, she<br><sup>6</sup>#</div>

					</div>
					
        `,
        answer: ["have", "has", "been", "haven't", "working", "hasn't"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P21-E1",
    exerciseKey: "img/FriendsPlus/Page21/Key/E2answerKey.png",
    component: T6v2,
    hintBox: [
      {
        src: [
          "been",
          "has",
          "hasn't",
          "have",
          "haven't",
          "<del>we</del>",
          "working",
        ],
        borderColor: "rgb(0,103, 178)",
        width: 720,
      },
    ],
    inputSize: 665,
    stylesTextInput: {
      fontSize: "22px",
      //textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Write sentences using the present perfect continuous.
				`,
        color: "rgb(0,103, 178)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px'>
				<div>They / learn / how to surf <br>
				<u style='color: gray'>&ensp;They've been learning how to surf.&ensp;</u></div>

				
					<div><b>1 </b>Dan / play his guitar all day</div>
					<div>#.</div>

					<div><b>2 </b>We / not study for long</div>
					<div>#.</div>

					<div><b>3 </b>Hannah / learn Japanese for a year</div>
					<div>#.</div>

					<div><b>4 </b>Where / they live / ?</div>
					<div>#</div>

					<div><b>5 </b>George / not sleep well recently</div>
					<div>#.</div>

					<div><b>6 </b>you / have swimming lessons / ?</div>
					<div>#</div>
					<div>Yes / I</div>
					<div>#.</div>
          </div>
					
        `,
        answer: [
          "Dan's been playing his guitar all day",
          "We haven't been studying for long",
          "Hannah has been learning Japanese for a year",
          "Where have they been living?",
          "George hasn't been sleeping well recently",
          "Have you been having swimming lessons?",
          "Yes, I have",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P21-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page21/Key/E3answerKey.png",
    component: Circle_Write,
    // inputSize: 110,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: `
  		Choose the correct words to complete Amber's message.
  				`,
        color: "rgb(0,103, 178)",
        star: 2,
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //2
          `a <br> b <br> c`, //3
          `a <br> b <br> c`, //4

          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //2
          `a <br> b <br> c`, //3
          `a <br> b <br> c`, //4
        ],
        answers: ["0-8", "1-0", "2-0", "3-8", "4-4", "5-0", "6-4", "7-8"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative'>
  		
 		  <img src='img/FriendsPlus/Page21/E3/1.jpg' style="padding:2px; width: 16cm; height:16cm"/>
     
  		<div style="font-size:20px; margin-bottom: 20px; margin-top: 20px"><strong>a</strong>&ensp; been &emsp;<b style="border:2px solid gray;border-radius:50%;padding:5px">b</b> &ensp;been doing &emsp;<b>c</b>&ensp; done</div>
  		<div style='display:flex;font-size:20px'>

  	<div style=''>
  	  <div style ='display: flex'>
<div style='margin-right: 150px'>
      
  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>1</b>&ensp;</div>
  		  <div><input id='0' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  sending <br> been sending <br> sent
  		</div>
  		</div>

  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>2</b>&ensp;</div>
  		  <div><input id='1' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  been <br> been being <br> was being
  		</div>
  		</div>
 
  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>3</b>&ensp;</div>
  		  <div><input id='2' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  ’ve been <br> ’ve <br> been
  		  </div>
  		</div>

  	  <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>4</b>&ensp;</div>
  		<div><input id='3' type='Circle'/></div>
  		<div style='margin-left:20px'>
  		gone <br> been gone <br> been going

  		</div>
  	  </div>
</div>
<div>
  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>5</b>&ensp;</div>
  		  <div><input id='4' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  been <br> has been <br> been being
  		</div>
  		</div>

  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>6</b>&ensp;</div>
  		  <div><input id='5' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  ’ve met <br> meeting <br> ’ve been meeting
  		</div>
  		</div>

  		<div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>7</b>&ensp;</div> 
  		  <div><input id='6' type='Circle'/></div>
  		  <div style='margin-left:20px'>
  		  not been learning <br> hasn’t learned <br> has been not learning
  		  </div>
  		</div>

  	  <div style="display: flex;margin-left:40px; margin-bottom: 20px">
      <div style='display: flex'><b>8</b>&ensp;</div>
  		<div><input id='7' type='Circle'/></div>
  		<div style='margin-left:20px'>
  		talked <br> been talked <br> been talking

  		</div>
  	  </div>
</div>

  	  </div>
  	</div>

    </div>

    </div>

  		`,
    },
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P21-E4",
    exerciseKey: "img/FriendsPlus/Page21/Key/E4answerKey.png",
    component: T6,
    hintBox: [
      {
        src: [
          "borrow my laptop",
          "break my tablet",
          "do homework",
          "eat my chocolate",
          "listen to me",
          "play music",
          "wear my clothes",
        ],
        borderColor: "rgb(28, 192, 217)",
        width: 850,
      },
    ],
    inputSize: 900,
    textareaStyle: { width: 930 },
    stylesTextInput: {
      fontSize: "23px",
      //textAlign: 'center',
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Imagine you are upset with your brother or sister. Write six things you're unhappy about. Use the present perfect simple and continuous with the ideas below, or your own ideas.
				`,
        color: "rgb(28, 192, 217)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<hintBox id='0'></hintBox>
        <div style='line-height: 50px; '>
				<i style='color: gray'>She's been playing her music really loudly.</i>
				<div>#.</div>
				<div>#.</div>
				<div>#.</div>
				<div>#.</div>
				<div>#.</div>
				<div>#.</div>

        </div>
        `,
        answer: [
          "She's borrowed my laptop without asking",
          "He's broken my tablet",
          "He hasn't been doing his homework",
          "She's eaten my chocolate",
          "She hasn't been listening to me",
          "He's been wearing my clothes",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P21-E5",
    exerciseKey: "img/FriendsPlus/Page21/E5/Key/answerKey.png",
    component: T6v2,
    inputSize: 80,
    stylesTextInput: {
      fontSize: "20px",
      background: "transparent",
      textAlign: "center",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "5",
        title: `				
					Study the examples and complete the rules with <i>for</i> and <i>since</i>.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-style: italic;'>
						James has worked for a perfume company for twenty years.<br /> 
						Lucy hasn't had a sense of smell since birth.
					</div>

					<div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(255, 255, 255); border: 1px solid rgb(28, 192, 217); padding: 10px;">
						<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(28, 192, 217); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase;">
							RULES
						</div>

						<div style='display: flex; gap: 20px; flex-direction: column; font-size: 20px; margin-top: 10px; padding: 0 20px'>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>1</span>
								<div>We use # to talk about the point in time an activity started.</div>
							</div>
							<div style='display: flex; gap: 10px;'>
								<span style='font-weight: bold;'>2</span>
								<div>We use # to talk about the period of time up to the present.</div>
							</div>
						</div>
					</div>
        `,
        // title: `
        // <div style="margin-top: 20px; width: 550px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(255, 255, 255); border: 1px solid rgb(28, 192, 217); padding: 10px;">
        // 	<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(28, 192, 217); color: white; padding: 5px 10px; border-radius: 20px; text-transform: uppercase;">
        // 	RULES
        // 	</div>

        // 	<div style="margin-top: 10px; padding: 0 20px">
        // 		<div style='font-size: 20px;'>
        // 			<div>
        // 				When did you last <sup>1</sup> <input id='0' width='200px' />?
        // 			</div>
        // 			<div>
        // 				At what age did you first <sup>2</sup> <input id='1' width='170px' />?
        // 			</div>
        // 			<div>
        // 				When was the last time that you <sup>3</sup> <input id='2' width='150px' />?
        // 			</div>
        // 			<div>
        // 				What was the last <sup>4</sup> # that you <sup>5</sup> #?
        // 			</div>
        // 			<div>
        // 				What did you <sup>6</sup> # last <sup>7</sup> #?
        // 			</div>
        // 		</div>
        // 	</div>
        // </div>
        // `,
        answer: ["since", "for"],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P21-E6",
    exerciseKey: "img/FriendsPlus/Page21/E6/Key/answerKey.png",
    component: T6v2,
    inputSize: 180,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
					Complete the sentences with the present perfect form of the verbs in brackets and <i>for</i> or <i>since</i>.
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>1</span>
							<div>I # this happy # weeks. (not feel)</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>2</span>
							<div>We # him # last month. (not see)</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>3</span>
							<div>They # that dog # years. (have)</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>4</span>
							<div>She # in London # 2010. (live)</div>
						</div>
					</div>
        `,
        answer: [
          `haven't felt`,
          "for",
          `haven't seen`,
          "since",
          "have had",
          "for",
          "has lived",
          "since",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-US-P9-E7",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: ["always", "for weeks", "never", "since", "this year", "today"],
        borderColor: "rgb(28, 192, 217)",
        width: 500,
      },
      {
        src: ["I have never visited Huế."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 350,
      },
      {
        src: ["I haven’t watched TV for weeks."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 2,
        width: 420,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
				Talk about experiences using the 
				present perfect. Use the time expressions below.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<hintbox id='0'></hintbox>
					<div style='margin-top: 20px;'><hintbox id='1'></hintbox></div>
					<div style='margin-top: 20px; margin-left: 300px;'><hintbox id='2'></hintbox></div>
				`,
        answer: [],
      },
    ],
  },
  8: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-US-P9-E7",
    audio: "",
    video: "",
    component: T6,
    finished: {
      text: "Talk about an unforgettable experience using the present perfect.",
    },
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "",
        title: `
				`,
        color: "rgb(28, 192, 217)",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<finished></finished>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
