import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P17-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page17/Key/E1answerKey.png",
    component: T6,
    inputSize: 105,
    isHiddenCheck: true,
    // checkUppercase: true,
    maxLength: 8,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      borderBottom: "1px solid gray",
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.`,
        color: "rgb(29,193,224)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
				<div style='margin-top: 20px; display: flex; gap: 30px;max-width:490px;margin-left:50px;margin-bottom:20px'>
				<div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(29,193,224);'>
				  <div><span style="margin-right:20px">been</span><span style="margin-right:20px;">ever</span><span style="margin-right:20px">finished</span><span style="margin-right:20px">has</span><span style="margin-right:20px">hasn't</span><span style="text-decoration:line-through">have</span></div>
				  <div><span style="margin-right:20px;margin-left:80px">haven't</span><span style="margin-right:20px;margin-left:10px">he</span><span style="margin-right:20px;margin-left:10px">made</span><span style="">you</span></div>
				  
				</div>
				
			  </div>
		
				<div style='position: relative; font-size: 23px'>
				  <div><img src='img/FriendsPlus/Page17/E1/1.jpg' style='width: 19cm; height: 12cm' /></div>
				  <div style='position: absolute; top: 67px; left: 39px;'><b>Have / Has</b><br><u style='color: gray'>&ensp;Have&ensp;</u><br><br><sup>4</sup>#</div>
          <div style='position: absolute; top: 67px; left: 197px;'><b>Subject</b><br>I / <sup>1</sup>#  / we /<br>you / they<br><sup>5</sup>#  / she / it</div>
				  <div style='position: absolute; top: 67px; left: 434px;'><b>Past participle</b><br><sup>2</sup># to the USA?<br><sup>3</sup># a mistake?<br><sup>6</sup>#?</div>
				  
          <div style='position: absolute; top: 273px; left: 39px;'>Have you / Has she <sup>7</sup># tasted curry?</div>
				  
          <div style='position: absolute; top: 355px; left: 39px;'>Yes, I have. / No, I <sup>8</sup>#.<br>Yes, she has. / No, she <sup>9</sup>#.</div>
				  
				</div>
			  </div>
        `,
        answer: [
          "Has / has",
          "you",
          "he",
          "been",
          "made",
          "finished",
          "ever",
          "haven't",
          "hasn't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P17-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page17/Key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      border: "none",
      borderBottom: "none",
      fontSize: "25px",
      fontWeight: 600,
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
			Complete the online chat using the 
correct present perfect form of the verbs.`,
        color: "rgb(29,193,224)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='margin-top: 20px; display: flex; gap: 30px;max-width:490px;margin-left:100px;margin-bottom:20px'>
			<div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(29,193,224); font-size: 25px'>
			  <div><span style="margin-right:20px;">celebrate</span><span style="margin-right:20px">do</span><span style="margin-right:20px">finish</span><span style="margin-right:20px">get</span><span style="margin-right:20px;">go</span><span style='margin-right:20px;'>happen</span><s style="margin-right:20px">have</s><span style="">pass</span></div>
			  
			  
			</div>
			
		  </div>
	
			<div style='position: relative;'>
			  <div><img src='img/FriendsPlus/Page17/E2/1.jpg' width='100%' /></div>
			  <div style='position: absolute; top: 305px; left: 238px;'>#</div>
			  <div style='position: absolute; top: 428px; left: 78px;'>#</div>
			  <div style='position: absolute; top: 428px; left: 432px;'>#</div>
			  <div style='position: absolute; top: 548px; left: 156px;'>#</div>
			  <div style='position: absolute; top: 630px; left: 77px;'>#</div>
			  <div style='position: absolute; top: 630px; left: 325px;'>#</div>
			  <div style='position: absolute; top: 750px; left: 180px;'>#</div>
			  <div style='position: absolute; top: 750px; left: 406px;'>#</div>
			  <div style='position: absolute; top: 790px; left: 134px;'>#</div>
			  <div style='position: absolute; top: 790px; left: 410px;'>#</div>
			  <div style='position: absolute; top: 917px; left: 277px;'>#</div>
			  <div style='position: absolute; top: 917px; left: 525px;'>#</div>
			  <div style='position: absolute; top: 1031px; left: 152px;'>#</div>
			  <div style='position: absolute; top: 1031px; left: 422px;'>#</div>
			  <div style='position: absolute; top: 1071px; left: 62px;'>#</div>
	
			  
	
			  
			  
			</div>
		  </div>
			`,
        answer: [
          "'s happened",
          "Has",
          "finished",
          "hasn't",
          "Have",
          "got",
          "have",
          "'ve passed",
          "'ve",
          "done",
          "Have",
          "celebrated",
          "have",
          "'ve",
          "been",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P17-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page17/Key/E3answerKey.png",
    component: T6,
    inputSize: 250,
    maxLength: 40,
    titleQuestion: [
      {
        num: "3",
        title: `Correct the words.`,
        color: "rgb(29,193,224)",
        star: 2,
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      textAlign: "center",
    },
    isHiddenCheck: true,
    questionImage: [],
    questions: [
      {
        title: `
      <div style='font-size: 23px; line-height: 50px'>
			<s style='color: gray'><b>Did</b></s> you ever ridden an elephant? <u style="font-weight:bold; color: gray">&ensp;Have&ensp;</u><br>
				<div><strong style="margin-right:15px">1</strong>She’s never <b>ate</b> Greek food.# </div>
				<div><strong style="margin-right:15px">2</strong><b>Katy gone</b> to the beach.# </div>
				<div><strong style="margin-right:15px">3</strong>I’<b>ve left</b> my phone at home yesterday. # </div>
				<div><strong style="margin-right:15px">4</strong>We <b>didn’t see</b> Max since last year. # </div>
				<div><strong style="margin-right:15px">5</strong>‘<b>Have</b> you like the show?’ ‘Yes!’  # </div>
			</div>
        `,
        answer: ["eaten", "Katy's gone", "left", "haven't seen", "Did"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P17-E4",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page17/Key/E4answerKey.png",
    component: T6,
    inputSize: "300px",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `Complete the mini-dialogues with the 
				present perfect or past simple form of the verbs 
				in brackets.`,
        color: "rgb(29,193,224)",
        star: 2,
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 30,

      textAlign: "center",
    },
    questions: [
      {
        title: `
              <div style='font-size: 23px; line-height: 40px'>
			  <div><strong style="margin-right:15px">A</strong><b><u style='color: gray'>&ensp;Have you ever been&ensp;</u></b> (you / go) to Brazil? </div>
			  <div><strong style="margin-right:15px">B</strong>Yes, I <b><u style='color: gray'>&ensp;have&ensp;</u></b> . I <b><u style='color: gray'>&ensp;went&ensp;</u></b> (go) there. 
			  <div><strong style="margin-right:15px">1</strong><b style="margin-right:15px">A</b>We # (never see) a wild tiger. </div>
			  <div><strong style="margin-right:15px;margin-left:30px">B</strong>I # ! I # (see) one last year in India.</div>
			  <div><strong style="margin-right:15px">2</strong><b style="margin-right:15px">A</b> I # (enjoy) that film yesterday. </div>
			  <div><strong style="margin-right:15px;margin-left:30px">B</strong>I # (not). I # (read) 
			  the book last year and it was better.</div>
			  <div><strong style="margin-right:15px">3</strong><b style="margin-right:15px">A</b># (he / tell) you the news? </div>
			  <div><strong style="margin-right:15px;margin-left:30px">B</strong>Yes #. He # (talk) to 
			  me last night.</div>
			  <div><strong style="margin-right:15px">4</strong><b style="margin-right:15px">A</b># (they / ever / try) sushi? </div>
			  <div><strong style="margin-right:15px;margin-left:30px">B</strong> No, # (not). They hate fish.
			  </div>
			  </div>
        `,
        answer: [
          "'ve never seen",
          "have",
          "saw",
          "enjoyed",
          "didn't",
          "read",
          "Has he told",
          "he has",
          "talked",
          "Have they ever tried",
          "they haven't",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P17-E5",
    exerciseKey: "/img/FriendsPlus/Page17/Key/E5answerKey.png",
    audio: "",
    video: "",
    inputSize: 705,
    maxLength: 200,
    component: T6,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title: `Write questions using the present 
				perfect or past simple. Then write answers that 
				are true for you.`,
        color: "rgb(29,193,224)",
        star: 3,
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      height: 29,
      borderStyle: "2px dotted",
      textAlign: "left",
    },

    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><i style='font-size:20px; color: rgb(245,54,92)'>*Write the true question at the first line, then write your own answer at the second line.</i></div>
        <div style='font-size: 22px; line-height: 40px'>
				<div>you / ever / go / abroad ?</div>
				<u style='color: gray'><b>&ensp;Have you ever been abroad?&ensp;</b></u><br>
				<u style='color: gray'><b>&ensp;Yes, I have. I’ve been to Italy and Poland.&ensp;</b></u>
				<div><strong style="margin-right:15px">1</strong>your parents / ever / live / in a different town ?</div>
				<div>#</div>
				<div>#</div>
				<div><strong style="margin-right:15px">2</strong>you / go / on holiday / last summer ?</div>
				<div>#</div>
				<div>#</div>
				<div><strong style="margin-right:15px">3</strong>you / eat / tasty meal / in the last week ?</div>
				<div>#</div>
				<div>#</div>
				<div><strong style="margin-right:15px">4</strong>you / read / good books / recently ? </div>
				<div>#</div>
				<div>#</div>
			  </div>
        `,
        answer: [
          "Have your parents ever lived in a different town?",
          "",
          "Did you go on holiday last summer?",
          "",
          "Have you eaten a tasty meal in the last week?",
          "",
          "Have you read any good books recently?",
          "",
        ],
      },
    ],
  },
};

export default json;
