import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P41-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page41/key/E1answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
    },

    isHiddenCheck: true,
    inputSize: 160,
    // maxLength: 9,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the table with the correct form of <i>have to, need to, must</i> and <i>should</i>.
				`,
        color: "#1CC0D5",
        star: 1,
      },
    ],
    questions: [
      {
        title: `
					
        <div style='font-size: 22px; position: relative;'>
        <img style='width: 17cm; height: 16cm' src='img/FriendsPlus/Page41/E1/1.jpg' />
             
              
              <div style=" position: absolute; top:75px; left: 160px; width: 415px ">
              I <u style='color: gray'>have to</u> leave soon. It’s getting 
              dark.<br>
              She <sup>1</sup># study maths.
              We <sup>2</sup># eat now. We can wait.
              </div>
              <div style=" position: absolute; top:214px; left: 160px; width: 415px ">
              You <sup>3</sup># train to be a nurse.
              He <sup>4</sup># do a degree to be an actor.
              </div>
              <div style=" position: absolute; top:338px; left: 160px; width: 415px ">
              I <sup>5</sup># be late for college.
              We <sup>6</sup># obey the rules.
              </div> 
              <div style=" position: absolute; top:432px; left: 160px; width: 415px ">
              He <sup>7</sup># apply for the course.
              It sounds interesting.
              They <sup>8</sup># worry. It isn’t important.
              </div>   
          </div>
        `,
        answer: [
          `has to`,
          `don't have to`,
          `need to`,
          `doesn't need to`,
          `mustn't`,
          `must`,
          `should`,
          `shouldn't`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P41-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page41/key/E2answerKey.png",
    component: T6v2,
    maxLength: 85,
    inputSize: 700,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Make sentences using <i>have to, need to, must</i> and <i>should</i>.
				`,
        color: "#1CC0D5",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:37px; font-size: 22px'>
        <div>Alfie / not need / study / foreign languages<br>
        <u style='color: gray'>&ensp;Alfie doesn’t need to study foreign languages.&ensp;</u>
        </div>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        you / not must / forget  <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        he / should / do more homework   <br> <input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        we / not have / wear school uniforms   <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        
        <b>4</b>
        <div style='margin-left:10px'>
        James / need / work harder in class <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>5</b>
        <div style='margin-left:10px'>
        Matilda / have / pass the exam  <br> <input/> 
        </div>
        </div>
    
        <div style ='display:flex'>
        <b>6</b>
        <div style='margin-left:10px'>
        you / not should / go to bed so late    <br> <input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>7</b>
        <div style='margin-left:10px'>
        we / not must / use phones in class    <br> <input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>8</b>
        <div style='margin-left:10px'>
        they / not have / go to school today  
  <br> <input/>
        </div>
        </div>
        </div>
				
				`,
        answer: [
          "You mustn't forget.",
          "He should do more homework.",
          "We don't have to wear school uniforms.",
          "James needs to work harder in class.",
          "Matilda has to pass the exam.",
          "You shouldn't go to bed so late.",
          "We  mustn't use phones in class.",
          "They don't have to go to school today.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P41-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page41/key/E3answerKey.png",
    component: T6v2,
    maxLength: 25,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Correct the words.
  				`,
        color: "rgb(28,190,215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='display:flex'>
       <div style='flex:1'>
       <img style='width: 14cm' src ='img/FriendsPlus/Page41/E3/1.jpg'/>

       </div>
       <div style='flex:1;margin-left:10px;line-height:2'>
       <div><b>ANSWER:</b></div>

       <b>1</b>&ensp; <input/> <br>
       <b>2</b>&ensp; <input/> <br>
       <b>3</b>&ensp; <input/> <br>
       <b>4</b>&ensp; <input/> <br>
       <b>5</b>&ensp; <input/> <br>
       <b>6</b>&ensp; <input/> <br>

       </div>
       </div>
		
				`,
        answer: [
          "don't have to be",
          "must do",
          "should help",
          "doesn't have to be",
          "should",
          "need to",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P41-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // inputSize: 1000,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Invent an award for young people. Decide the things you have to do to get it. Include <i>have to, need to, must</i> and <i>should</i> in your answer. 
        
		`,
        color: "rgb(28,190,215)",
        star: 3,
      },
    ],
    textareaStyle: { width: 1200 },
    // maxLength:10,
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
<div style='margin-top:10px;width:1200px;'>
 <b style='color: gray'>The award is called the Young Helper’s Award. It’s for giving help to your community. You must decide who or what to help, such as other students or animals in need. To get the award, you have to spend a few hours a week doing it ...
 
 </b>
 <textarea id='0' rows='8'></textarea>

</div>

        `,
        answer: [""],
      },
    ],
  },
};

export default json;
