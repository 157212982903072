import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U7-P70-E1",
    audio: "Audios/2.20.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page70/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 300,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
			<headphone name="2.20"></headphone >		
			Match the verbs in <span style="color: rgb(28,190,215);">blue</span> in the text with the noun forms in the box. Listen and check.

													`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="border: 2px solid rgb(7,114,186); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">an aim</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a ban</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a belief</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a boycott</p>

					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a campaign</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a donation</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">an end</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a proposal</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a protest</p>

					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a signature</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a supporter</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a volunteer</p>
					  
		</div>
    <i style="font-weight: bold;">campaign - a campaign</i>
    <h1 style="margin-top: 30px">Answers</h1>

    <div style="display: flex;flex-wrap: wrap; gap:20px;margin-top:30px">
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#,</span>
    <span style="font-size: 25px">#</span>
    
    </div>
      
    

		<div style="display: flex;flex-wrap: wrap; gap:30px;margin-top:30px">
		<div ><img src="img/FriendsPlus/Page70/13.jpg" width="500px"/></div>	
		<div ><img src="img/FriendsPlus/Page70/10.jpg" width="500px"/></div>	
		<div ><img src="img/FriendsPlus/Page70/11.jpg" width="500px"/></div>	
		<div ><img src="img/FriendsPlus/Page70/12.jpg" width="500px"/></div>	
		
		</div>
													`,
        answer: [
          "campaign - a campaign",
          "donate - a donation",
          "volunteer - a volunteer",
          "sign - a signature",
          "ban - a ban",
          "believe - a belief",
          "boycott - a boycott",
          "protest - a protest",
          "aim - an aim",
          "propose - a proposal",
          "end - an end",
          "support - a supporter",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U7-P70-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 300,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Read the online petitions and decide if you support
        the ideas. Then work with a partner and explain
        why you agree or disagree with each.

													`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="display: flex;flex-wrap: wrap; gap:30px;margin-top:30px">
		<div ><img src="img/FriendsPlus/Page70/13.jpg" width="500px"/></div>	
		<div ><img src="img/FriendsPlus/Page70/10.jpg" width="500px"/></div>	
		<div ><img src="img/FriendsPlus/Page70/11.jpg" width="500px"/></div>	
		<div ><img src="img/FriendsPlus/Page70/12.jpg" width="500px"/></div>	
		
		</div>
													`,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-SB-demo-2023-U7-P70-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: 'center',
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
						
        Use the verbs in exercise 1  to create your own petition. It can be serious or funny. Can you
		persuade anyone in the class to sign it?
												`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left: 200px"><img src="img/FriendsPlus/Page70/9.jpg"/></div>	
												`,
        answer: [],
      },
    ],
  },
};

export default json;
