import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P62-E1",
    // audio: "Audios/2.13.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E1answerKey.png",
    component: T6,
    // maxLength: 6,
    inputSize: 1000,
    stylesTextInput: {
      background: "none",
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Write sentences that are true for you using the present perfect.
									`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div>
        <div style='line-height:45px; font-size: 22px'>
        I / write / a story <br>
        <u style='color: gray'><b>&ensp;I’ve written a story. OR&ensp;</b></u> <br>
        <u style='color: gray'><b>&ensp;I haven’t written a story.&ensp;</b></u>
        </div>
        <div>
          <b>1</b>&ensp; My dad / go / to Russia <br>
          #
        </div>

        <div>
          <b>2</b>&ensp; I / touch / a snake <br>
          #
        </div>

        <div>
          <b>3</b>&ensp; My friends / study / English this year<br>
          #
        </div>

        <div>
          <b>4</b>&ensp; My friends / study / English this year <br>
          #
        </div>

        <div>
        <b>5</b>&ensp; My best friend / read / a Harry Potter book <br>
        #
      </div>

      <div>
      <b>6</b>&ensp; My mum / fly / in a helicopter <br>
      #
    </div>

        

      </div>
									`,
        answer: [
          `My dad's been to Russia. / My dad hasn't been to Russia.`,
          `I've touched a snake. / I haven't touched a snake.`,
          `My friends have studied English this year. / My friends haven't studied English this year.`,
          `I've watched a film today. / I haven't watched a film today.`,
          `My best friend has read a Harry Potter book. / My best friend hasn't read a Harry Potter book.`,
          `My mum's flown in a helicopter. / My mum hasn't flown in a helicopter.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P62-E2",
    // audio: "Audios/2.14.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E2answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Choose the correct words.

									`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `for / since`, //1
          `a_week / last_week.`, //1
          `for / since`, //1
          `for / since`, //1
          `six_months / June`,
          `for / since`,
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:50px'>
          I’ve been here since <b>four days / <span style='padding: 5px; border: 3px solid black; border-radius: 50%'>Tuesday</span>.</b>
          <div><b>1</b>&ensp; Paco has known Javier <input id='0' type='Circle'/> ages.</div>

          <div><b>2</b>&ensp; They’ve felt ill since <input id='1' type='Circle'/> to carry when hiking or climbing.</div>
          
          <div><b>3</b>&ensp; I’ve loved dancing <input id='2' type='Circle'/> I was a child.</div>
          
          <div><b>4</b>&ensp; Lisa’s had a pet cat <input id='3' type='Circle'/> three years. </div>

          <div><b>5</b>&ensp; We’ve been friends since <input id='4' type='Circle'/>. </div>

          <div><b>6</b>&ensp; I’ve played volleyball <input id='5' type='Circle'/> 2013.</div>
    
        </div>
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P62-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E3answerKey.png",
    component: T6v2,
    maxLength: 70,
    inputSize: 700,
    checkUppercase: true,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Correct the mistakes in bold.
									`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
      <div style='line-height:45px; font-size: 23px'>
        <div>
        <b> <s>Have</s> </b>&ensp;Abdul seen this film? <br>
        <u><b style='color: gray'>&ensp;Has Abdul seen this film?&ensp;</b></u>
        </div>
        <div>
          <b>1</b>&ensp; Has <b>ever Natalie eaten</b> sushi? <br>
          #
        </div>

        <div>
          <b>2</b>&ensp; Have they <b>went</b> to the USA before? <br>
          #
        </div>

        <div>
          <b>3</b>&ensp; <b>Janine has</b>  gone out? <br>
          #
        </div>

        <div>
          <b>4</b>&ensp; Has he bought it?’ ‘Yes, <b>he’s</b>.’ <br>
          #
        </div>

        <div>
        <b>5</b>&ensp; Have you <b> lived ever abroad</b> ? <br>
        #
      </div>

      <div>
      <b>6</b>&ensp; How long <b> your uncle has</b> been a doctor? <br>
      #
    </div>

      </div>
									`,
        answer: [
          `Has Natalie ever eaten sushi?`,
          `Have they been to the USA before?`,
          `Has Janine gone out?`,
          `'Has he bought it?' 'Yes, he has.'`,
          `Have you ever lived abroad?`,
          `How long has your uncle been a doctor?`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P62-E4",
    // audio: "Audios/2.15.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E4answerKey.png",
    component: T6,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      borderBottom: "2px solid black",
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Complete the dialogue using the present perfect or past simple form of the verbs in brackets.
									`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:45px; font-size: 22px'>
        <div style='display:flex'> <b>Ana</b> <span style='margin-left:20px'> <b><u style='color: gray'>&ensp;Have you ever been&ensp;</u></b> (you / ever / go) to a food festival?</span></div>

        <div style='display:flex'> <b>Tim</b> <span style='margin-left:20px'>  No, I <sup>1</sup> # (not). In fact, I <sup>2</sup> # (never / hear) of a food festival!</span></div>

        <div style='display:flex'> <b>Ana</b> <span style='margin-left:20px'> Really? My town <sup>3</sup> # (have) a cheese festival for years! I <sup>4</sup> # (go) to it three times.</span></div>

        <div style='display:flex'> <b>Tim</b> <span style='margin-left:20px'>Oh! When <sup>5</sup> # (be) the last time?</span></div>

        <div style='display:flex'> <b>Ana</b> <span style='margin-left:20px'>Last summer.</span></div>

        <div style='display:flex'> <b>Tim</b> <span style='margin-left:20px'>So, <sup>6</sup> # (you / eat) a lot of cheese there?</span></div>

        <div style='display:flex'> <b>Ana</b> <span style='margin-left:20px'>Yes, I <sup>7</sup> # (do)! I don’t think I <sup>8</sup> # (ever / eat) so much cheese! I <sup>9</sup> # (go) with my cousin. We <sup>10</sup> # (have) a really good time</span></div>
        
        <div style='display:flex'> <b>Tim</b> <span style='margin-left:20px'>Good! But don’t invite me next time, OK?</span></div>

        <div style='display:flex'> <b>Ana</b> <span style='margin-left:20px'>Why not?</span></div>

        <div style='display:flex'> <b>Tim</b> <span style='margin-left:20px'>I really hate cheese!</span></div>
    </div>
		
	
			
								`,
        answer: [
          `haven't`,
          `'ve never heard`,
          `'s had / has had `,
          `'ve been`,
          `was`,
          `did you eat`,
          `did`,
          `'ve ever eaten`,
          `went`,
          `had`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P62-E5",
    // audio: "Audios/2.15.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page62/key/E5answerKey.png",
    component: Circle_Write,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 830,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Choose the incorrect word.
									`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          fontSize: 23,
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `wonderful / spectacular / nice`, //1
          `amazing / interesting / long`, //1
          `furious / sad / exhausted`, //1
          `bad / terrible / awful`, //1
        ],
        answers: ["0-8", "1-0", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:60px; font-size: 23px'>
        We had an <b>really / ̶v̶̶e̶̶r̶̶y̶  / absolutely</u>  amazing time.</b>
          <div><b>1</b>&ensp; The views from this window are absolutely <input id='0' type='Circle'/>.</div>

          <div><b>2</b>&ensp; We saw a very <input id='1' type='Circle'/> programme on TV last night.</div>
          
          <div><b>3</b>&ensp; He was totally <input id='2' type='Circle'/>.</div>
          
          <div><b>4</b>&ensp; That was a completely <input id='3' type='Circle'/> film. </div>

    
        </div>
      `,
    },
  },
  6: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P62-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in groups. Ask and answer questions 1–5 about the jobs in exercises 2 and 3. Then tell the rest of the class which jobs are most popular in your group.

        `,
        color: "#4b3a97",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-top:30px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">1</span>
            <span style="font-size: 25px">Which careers involve helping people?
			</span>
        </div>
		
        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">2</span>
            <span style="font-size: 25px">Which careers are more physical?</span>
        </div>

        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">3</span>
            <span style="font-size: 25px">Which careers can make you more money?</span>
        </div>

        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">4</span>
            <span style="font-size: 25px">Which careers involve studying for many
			years at university?</span>
        </div>

        <div style="margin-top:10px">
            <span style="font-size: 25px;font-weight:bold;margin-right:10px;">5</span>
            <span style="font-size: 25px">Which job would you like to do?</span>
        </div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
