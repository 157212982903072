import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P16-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page16/Key/E1answerKey.jpg",
    component: Circle_Write,
    inputSize: 200,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: `Which word is the odd one out?`,
        color: "rgb(245,128,36)",
        // prefix: { icons: ['far fa-comment'], text: '' },
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {
          width: 120,
          textAlign: "center",
        },
        answers: [``],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          padding: "5px",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "tasty | delicious | angry",
          "fantastic | scary | wonderful",
          "horrible | disgusting | sad",
          "tired | terrifying | exhausted",
          "interesting | fascinating | disgusting",
        ],
        answers: ["0-12", "1-6", "2-12", "3-6", "4-12"],
        initialValue: [],
      },
      Layout: `
      <div>scary&emsp; terrifying&emsp;<span style="border: 3px solid black;border-radius:50%;padding:5px"> delicious</span><div>
			<div><strong style="margin-right:15px">1</strong><input id="0" type="Circle"></div>
			<div><strong style="margin-right:15px">2</strong><input id="1" type="Circle"></div>
			<div><strong style="margin-right:15px">3</strong><input id="2" type="Circle"></div>
			<div><strong style="margin-right:15px">4</strong><input id="3" type="Circle"></div>
			<div><strong style="margin-right:15px">5</strong><input id="4" type="Circle"></div>
					
        `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P16-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page16/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      // border: "none",
      // borderBottom: "1px solid gray",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the blog post with the words.`,
        color: "rgb(245,128,36)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:490px;margin-left:100px;margin-bottom:20px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(245,128,36);'>
          <div><span style="margin-right:20px;text-decoration:line-through">amazing</span><span style="margin-right:20px;">delicious</span><span style="margin-right:20px">disgusting</span><span>exhausted</span></div>
          <div><span style="margin-right:20px;margin-left:120px">fascinating</span><span style="">wonderful</span></div>
          
        </div>
        
      </div>

        <div style='position: relative; font-size: 22px'>
          <img src='img/FriendsPlus/Page16/E2/1.jpg' style='width: 19cm; height: 19cm' />
          <div style='position: absolute; top: 45px; left: 71px; width: 573px; line-height: 41px'>
          Today, I’ve been to an <u style='color: gray'>&ensp;amazing&ensp;</u> food festival 
          with my family. We’ve learned a lot of 
          <sup>1</sup># things about food from different 
          countries. We tried a tasty Jamaican dish called 
          ‘Jerk chicken’. I thought it was absolutely 
          <sup>2</sup># ! My younger sister didn’t agree. 
          She thinks hot sauces are <sup>3</sup># ! She 
          looked sad until we found the Italian ice cream. 
          How <sup>4</sup># ! Now we’re home, I’m going 
          to go to bed. I’m <sup>5</sup># after all that food!
          
          </div>
      </div>
        `,
        answer: [
          "fascinating",
          "delicious",
          "disgusting",
          "wonderful",
          "exhausted",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P16-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page16/Key/E3answerKey.jpg",
    component: Circle_Write,
    inputSize: 200,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: `You are going to listen to an interview. 
        Read the sentences and choose the correct type 
        of answer you should be listening for.`,
        color: "rgb(245,128,36)",
        // prefix: { icons: ['far fa-comment'], text: '' },
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {
          width: 120,
          textAlign: "center",
        },
        answers: [``],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          padding: "5px",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "3px solid rgb(0, 173, 238)",
          padding: "5px",
        },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "adjective / place",
          "number / name",
          "number / place",
          "adjective / verb",
          " place / time period",
          "adjective / name",
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-6", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div>Anna is ........ a who writes about places.<strong><span style="border: 3px solid black;border-radius:50%;padding:5px">job</span>/ verb</strong><div>
			<div><strong style="margin-right:15px">1</strong>Anna saw grey whales in ............... in California.<strong><input id="0" type="Circle"></strong></div>
			<div><strong style="margin-right:15px">2</strong>Around ................ grey whales make the journey every year.<strong><input id="1" type="Circle"></strong></div>
			<div><strong style="margin-right:15px">3</strong>The grey whales travel ............... kilometres, from Alaska to Mexico.<strong><input id="2" type="Circle"></strong></div>
			<div><strong style="margin-right:15px">4</strong>Anna ............. a whale-watching cruise.<strong><input id="3" type="Circle"></strong></div>
			<div><strong style="margin-right:15px">5</strong>You can see the grey whales for ............ every year.<strong><input id="4" type="Circle"></strong></div>
			<div><strong style="margin-right:15px">6</strong>Anna was ........... with herself that she didn’t take any photos.<strong><input id="5" type="Circle"></strong></div>
					
        `,
    },
  },
  4: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P16-E4",
    audio: "/Audios/1-05 Friends Plus 8_WB.mp3",
    video: "",
    component: T6,
    exerciseKey: "/img/FriendsPlus/Page16/Key/E4answerKey.jpg",
    inputSize: 605,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 29,
      textAlign: "left",
    },
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: `<headphone src="/Audios/1-05 Friends Plus 8_WB.mp3" name="05"></headphone>&ensp; Listen to the interview and write 
        the answers to the questions in exercise 3.`,
        color: "rgb(245,128,36)",
        star: 2,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='font-size: 22px; line-height: 50px'>
            <div>Job: <u style='color: gray'><b>&ensp;travel blogger&ensp;<b></u></div>
            <div><strong style="margin-right:15px">1</strong>#</div>
            <div><strong style="margin-right:15px">2</strong>#</div>
            <div><strong style="margin-right:15px">3</strong>#</div>
            <div><strong style="margin-right:15px">4</strong>#</div>
            <div><strong style="margin-right:15px">5</strong>#</div>
            <div><strong style="margin-right:15px">6</strong>#</div>
          </div>

        `,
        answer: [
          "Dana Point / California",
          "20,000 / 20000",
          "almost 10,000 kilometres / 10,000 kilometres",
          "joined",
          "a few months",
          "furious",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P16-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    textareaStyle: { width: 900 },
    hideBtnFooter: true,
    // recorder: true,
    titleQuestion: [
      {
        num: "5",
        title: `
        Write about an interesting place you 
        have visited. Use extreme adjectives.`,
        color: "rgb(245,128,36)",
        star: 3,
      },
    ],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
              <div style='width: 900px'>
              <i style='color: gray'><b>I’ve visited the Cave of El Castillo in Spain. It has amazing
              cave paintings that are over 40,000 years old. It’s dark
              inside the cave and my younger brother thought it
              was terrifying.</b></i>
              <textarea id="0" rows="8"></textarea>
              </div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
