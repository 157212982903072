import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P58-E1",
    // audio: "Audios/2.09.mp3",

    video: "",
    recorder: true,
    hideBtnFooter: true,
    textareaStyle: {
      // background: 'none',
      width: 500,
      fontSize: 22,
      height: 330,
    },
    // exerciseKey: "img/FriendsPlus/Page58/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",

        title: `
        <span style="color:#493a90">Speaking</span> Look at the photo and answer the questions.
                `,
        color: "#493a90",
      },
    ],
    inputSize: 220,
    component: T6v2,
    textAlign: "center",
    hintBox: [
      {
        src: [
          "boarding",
          "holidays",
          "home",
          "leavers",
          "mixed",
          "primary",
          "rules",
          "secondary",
          "single-sex",
          "uniform",
        ],
        borderColor: "#1dbfd7",
        width: 500,
      },
    ],
    questions: [
      {
        title: `
        <div>Where are they? How much time do you spend studying every day?</div>
      <div style='display: flex'>
      
      <img src="img/FriendsPlus/Page58/E1/1.jpg" style='width: 13cm; height: 19cm'/>
      <div style='margin-left: 30px'><textarea id='1' rows='3'></div>

      </div>
						
												`,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P58-E2",
    // audio: "Audios/2.10_01.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E2answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        <span style="color:#493a90">Reading</span> Read the email. How many lessons a day has Alex got?
								`,
        color: "#493a90",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
        <div>
          <textarea id='0' rows="1"></textarea>
          <img src="img/FriendsPlus/Page58/E1/1.jpg" style='width:72%'/>
         </div>
								`,
        answer: [`eight`],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P58-E3",
    // audio: "Audios/2.10_01.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E3answerKey.png",
    component: MatchDots,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the text again. Match 1–5 with a–e.
								`,
        color: "#493a90",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "24px",
          //     left: "403px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #dc2c39",
          //     background: "white",
          //   },
          // }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "87px",
              left: "357px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "185px",
              left: "357px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "239px",
              left: "357px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "290px",
              left: "357px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "27px",
              left: "515px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "131px",
              left: "515px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 7
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "180px",
          //     left: "515px",
          //     width: "24px",
          //     height: "24px",
          //     borderRadius: "50%",
          //     border: "1px solid #dc2c39",
          //     background: "white",
          //   },
          // }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "515px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "288px",
              left: "515px",
              width: "24px",
              height: "24px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          }, // 10
        ],
        answers: ["0-7", "1-6", "2-4", "3-5"],
        initialValue: [],
      },
      Layout: `
          <div style='display: flex; justify-content: space-around; width:1000px; font-size: 26px; line-height:2; color: black'>
            <div>
                <div><b> 1  </b>The length of a school day has</div>
                <div><b> 2  </b>Mrs Davis hopes that the changes will</div>
                <div><b> 3  </b>Alex doesn’t have to</div>
                <div><b> 4  </b>A longer school day</div>
                <div><b> 5  </b>Alex doesn’t know</div>
            </div>    
            <div style='margin-left:150px' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> A </b></div>
                <div> will allow students to take up new classes. </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> B </b></div>
                <div> what new class to choose.</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> C </b></div>
                <div> changed in Alex’s school. </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> D </b></div>
                <div> do so much homework now. </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> E </b></div>
                <div> improve grades. </div>
              </div>
            </div>     
          </div>     
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
         
      `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P58-E4",
    audio: "Audios/1-23 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 130,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        <headphone name="23"></headphone >&ensp; Listen to Mike talking to his mum. Complete the sentences.
		`,
        color: "#574b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    checkUppercase: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 23px'>
        <div style='display:flex; align-items: center;'><b>1.</b>&ensp; Mike’s # is coming to visit him.</div>
        <div style='display:flex; align-items: center;'><b>2.</b>&ensp; Mike’s is coming to visit him #.</div> 
        <div style='display:flex; align-items: center;'><b>3.</b>&ensp; Patrick is going to stay for # weeks.</div> 
            


      </div>
        `,
        answer: [`cousin`, `Canada`, `eight`],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P58-E5",
    audio: "Audios/1-23 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page58/key/E5answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 80,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        <headphone name="23"></headphone >&ensp; Listen again and write M for Mike or P for 
        Patrick.
		`,
        color: "#574b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div style='display:flex; align-items: center;'><b>1.</b>&ensp; # doesn’t live in Canada.</div>
        <div style='display:flex; align-items: center;'><b>2.</b>&ensp; # has got six weeks of summer holiday.</div> 
        <div style='display:flex; align-items: center;'><b>3.</b>&ensp; # has got a longer summer holiday.</div> 
        <div style='display:flex; align-items: center;'><b>4.</b>&ensp; # has got a lot of breaks during the school year</div>
        <div style='display:flex; align-items: center;'><b>5.</b>&ensp; # misses his friends in the summer.</div> 
        <div style='display:flex; align-items: center;'><b>6.</b>&ensp; # must do lots of homework in the summer.</div> 
            


      </div>
        `,
        answer: [`M`, `M`, `P`, `M`, `P`, `P`],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P58-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page58/key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Imagine that your school has decided to change the duration of lessons. They will now last for ninety minutes each. Write a letter to your friend and talk about this change.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div style='display:flex'><b>1</b>&ensp; <span>What has changed in your school? How long are the lessons in your school now and how long were they before?</span></div>
        <div><b>2</b>&ensp; What do you think the advantage of longer lessons is? What benefits do your teachers think it will have?</div>
        <div><b>3</b>&ensp; What do you not like about this change?</div>
        <div><b>4</b>&ensp; Speculate about the future of this change. Do you think it will last? Say why or why not.</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page58/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
          
        </div>
      
  
  


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `breakfast`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
};

export default json;
