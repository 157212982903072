import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E13",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page32/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
       Cross out the incorrect phrase.
				`,
        color: "rgb(66,153,210)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `get_a_pension / get_old / get_a_job`,
          `get_a_job / get_old / get_a_degree`,
          `get_a_social_media_account / get_rich / 
          get a bank account`,
        ],
        answers: ["0-8", "1-4", "2-0"],
        initialValue: [],
      },
      Layout: `
			

      <div style='line-height: 50px'>

      <span>Free time: <strong>get a new hobby / <span style="text-decoration:line-through">get a pension</span> / 
      get a social media account</strong></span>
      
      <div style="margin-top: 5px; display: flex; gap: 20px;">
        <div style="font-weight: bold; text-align: right;">1</div>
        <div>
       Aged sixty-five :   <input id='0' type='Circle' />. 
        </div>
      </div>
      <div style="margin-top: 5px; display: flex; gap: 20px;">
        <div style="font-weight: bold; text-align: right;">2</div>
        <div>
        Success: <input id='1' type='Circle' />. 
         
        </div>
      </div>
      <div style="margin-top: 5px; display: flex; gap: 20px;">
        <div style="font-weight: bold; text-align: right;">3</div>
        <div>
        Money: <input id='2' type='Circle' />. 
         
        </div>
      </div>
    </div>

			  `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P32-E2",
    exerciseKey: "img/FriendsPlus/Page32/E2/Key/E2answerkey.png",
    audio: "",
    component: T6v2,
    inputSize: 255,
    maxLength: 26,
    stylesTextInput: {
      fontSize: "19px",
      background: "none",
      // border: "none",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the paragraphs with the 
        correct form of <i>get</i> and the words.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `

        <div style=" position:relative; font-size: 19px; margin-top:-15px">
        <img src="img/FriendsPlus/Page32/E2/1.jpg" style="width: 30cm;height: 21cm;" />
        
        <div style="position:absolute; top:291px; left:40px; width: 462px; line-height: 27px">
          When I was six years old, I got a new hobby . It 
          was dancing and I loved it from the start. After I left 
          school, I <sup>1</sup># with a dance company 
          and today I perform in theatres in London’s West 
          End. I won’t <sup>2</sup># as a dancer, but 
          the money isn’t important to me. Dancing is also 
          how I <sup>3</sup># . I’m actually going to <sup>4</sup># to my dance partner, Charlotte, 
          in a big wedding next year! We both hope to dance in 
          shows for a long time. When we <sup>5</sup># , 
          I’m sure we’ll still dance together at home!

        </div> 
        <div style="position:absolute; top:281px; left:630px; width: 455px;  line-height: 27px">
        My big sister just <sup>6</sup># in music at 
        university and I can’t believe she isn’t a student any 
        more! While she was studying, she did a lot of things 
        for the first time. She <sup>7</sup># so she 
        could pay for university. She <sup>8</sup>#<br>
        so she could drive to classes. She also 
        <sup>9</sup># and they started a band 
        together! Now they’re playing concerts and 
        trying to get famous. I hope it happens! 
        They’ve <sup>10</sup># to try and get <br>
        more fans for the band. 

        </div> 
        </div>

       
       
        
        

				`,
        answer: [
          `got a job`,
          `get rich`,
          `got a girlfriend`,
          `get married`,
          `get old`,
          `got a degree`,
          `got a bank account`,
          `got a driving licence`,
          `got a boyfriend`,
          `got social media accounts`,
        ],
      },
    ],
  },
  //   3: {
  //     unit: "Unit 2",
  //     id: "FP8-SB-demo-2023-U2-P29-E13",
  //     audio: "",
  //     video: "",
  //     component: Circle_Write,
  //     exerciseKey: "img/FriendsPlus/Page32/E3/Key/E3answerKey.png",
  //     titleQuestion: [
  //       {
  //         num: "3",
  //         title: `
  //         Choose the correct meaning.
  // 				`,
  //         color: "rgb(66,153,210)",
  //         star: 2,
  //       },
  //     ],
  //     question: {
  //       Write: {
  //         inputStyle: {},
  //         answers: [],
  //         initialValue: [],
  //       },
  //       Circle: {
  //         initialWordStyle: {
  //           padding: "-40px",
  //           fontWeight: 600,
  //           // fontStyle: "italic",

  //           color: "",
  //           fontSize: 22,
  //           borderRadius: "50%",
  //           // marginInline: '-1px',
  //         },
  //         selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
  //         limitSelect: 1,
  //         listWordsStyle: {},
  //         listWords: [
  //           `a. She has completed the school year.<br> b. She has passed her final university exams. <br> c. She has started her university course.`,
  //           ` a. They are giving Adam money.<br> b.They have always been rich. <br> c. They are making a lot of money.`,
  //           ` a. Someone phoned her.<br> b. Someone bought her a phone. <br> c. She bought a phone.`,
  //           `a. She’s passed her driving test.<br> b. She can’t drive a car. <br> c. She’s started driving lessons.`,
  //           `a. He saves money for when he’s older.<br> b. He doesn’t work now, but receives money. <br> c. He receives money for the work he does.`,
  //         ],
  //         answers: ["0-14", "1-26", "2-10", "3-0", "4-16"],
  //         initialValue: [],
  //       },
  //       Layout: `

  //       <div style='font-size: 22px'>

  //       My dad is getting old <br>
  //       <strong style="border-radius: 50%; border: 2px solid; padding:3px"> a </strong> He isn't young now .<br>
  //        <strong> b </strong> He likes his age. <br>
  //        <strong> c </strong> His friends are younger than he is. <br>

  //      <div style="margin-top: 5px; display: flex; gap: 20px;">
  //        <div style="font-weight: bold; text-align: right;">1</div>
  //        <div>
  //       Sarah has got a degree. <br/>   <input id='0' type='Circle' />
  //        </div>
  //      </div>
  //      <div style="margin-top: 5px; display: flex; gap: 20px;">
  //        <div style="font-weight: bold; text-align: right;">2</div>
  //        <div>
  //        Adam’s parents are getting rich. <br> <input id='1' type='Circle' />

  //        </div>
  //      </div>
  //      <div style="margin-top: 5px; display: flex; gap: 20px;">
  //        <div style="font-weight: bold; text-align: right;">3</div>
  //        <div>
  //        My sister got a phone for her birthday. <br> <input id='2' type='Circle' />

  //        </div>
  //      </div>
  //      <div style="margin-top: 5px; display: flex; gap: 20px;">
  //      <div style="font-weight: bold; text-align: right;">4</div>
  //      <div>
  //      Alicia has just got her driving licence. <br> <input id='3' type='Circle' />

  //      </div>
  //    </div>
  //    <div style="margin-top: 5px; display: flex; gap: 20px;">
  //    <div style="font-weight: bold; text-align: right;">5</div>
  //    <div>
  //    Michael gets a pension. <br> <input id='4' type='Circle' />

  //    </div>
  //  </div>
  //    </div>

  // 			  `,
  //     },
  //   },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E13",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page32/E3/Key/E3answerKey.png",
    component: Circle_Write,
    // inputSize: 110,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: `
        Choose the correct meaning.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-4", "1-8", "2-4", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='font-size: 20px; margin-bottom: 20px'>
      My dad is getting old. <br>
        <strong style="border-radius: 50%; border: 2px solid gray; padding:3px"> a </strong> He isn't young now .<br>
        <strong style=' padding:3px'> b </strong> He likes his age. <br>
        <strong style=' padding:3px'> c </strong> His friends are younger than he is. 
      </div> 
			<div style='display:flex;font-size:20px'>
		<div style='flex: 1'>
		  <b>1</b>&ensp;Sarah has got a degree.
		  <div style="display: flex;">
			<div style="display: flex; margin-left: 40px">
			  <div><input id='0' type='Circle'/></div>
			  <div style='margin-left:20px'>
				She has completed the school year. <br>
        She has passed her final university exams. <br> 
        She has started her university course.
			  </div>
			</div>
		  </div>
	  
		  <div style ='flex:1;'>
			<b>2</b>&ensp; Adam’s parents are getting rich.   <br>
			<div style="display: flex;margin-left:40px">
			  <div><input id='1' type='Circle'/></div>
			  <div style='margin-left:20px'>
			  They are giving Adam money. <br>
        They have always been rich. <br>
        They are making a lot of money.
			  </div>
			</div>
	  
			<b>3</b>&ensp;My sister got a phone for her birthday.  <br>
			<div style="display: flex;margin-left:40px">
			  <div><input id='2' type='Circle'/></div>
			  <div style='margin-left:20px'>
			  Someone phoned her. <br>
        Someone bought her a phone. <br>
        She bought a phone.
			  </div>
			</div>
	  
			<b>4</b>&ensp; Alicia has just got her driving licence.
		  <div style="display: flex;margin-left:40px">
			<div><input id='3' type='Circle'/></div>
			<div style='margin-left:20px'>
			  She’s passed her driving test. <br>
        She can’t drive a car. <br> 
        She’s started driving lessons.
	  
			</div>
		  </div>
			<b>5</b>&ensp; Michael gets a pension.
		  <div style="display: flex;margin-left:40px">
			<div><input id='4' type='Circle'/></div>
			<div style='margin-left:20px'>
      He saves money for when he’s older. <br>
      He doesn’t work now, but receives money. <br> 
      He receives money for the work he does.
	  
			</div>
		  </div>
	  
		  </div>
		</div>
	  
	
	  </div>
	  
	   
	  
			  
			`,
    },
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P32-E4",
    component: T6v2,
    textareaStyle: { width: 730 },
    hideBtnFooter: true,
    inputSize: 500,
    stylesTextInput: {
      fontSize: "24px",
    },

    titleQuestion: [
      {
        num: "4",
        title: `
				Answer the questions. Write complete 
sentences.
				`,
        color: "rgb(66,153,210)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display:flex;flex-direction:column">
        What do you plan to do in life before you get old?
        <strong style="text-decoration:underline; color: gray"> I plan to get a maths degree, get married and get rich. </strong>


        <span> <strong style="margin-right:30px">1 </strong> When did you first get a social media account? <br><span style="margin-left:50px">How many do you have now ?</span></span>
              <textarea id="0" rows=2></textarea>
              <span> <strong style="margin-right:30px">2 </strong> When did you last get a new hobby? Explain 
              what it is.</span>
              <textarea id="1" rows=2></textarea>

              <span> <strong style="margin-right:30px">3 </strong> Do you plan to get your driving licence in a few 
              years? Why / Why not?</span>
              <textarea id="2" rows=2></textarea>

              <span> <strong style="margin-right:30px">4</strong>What degree or job do you think you’ll get in 
              the future? Explain why</span>
              <textarea id="3" rows=2></textarea>

        </div>
				
				`,
        answer: [],
      },
    ],
  },
};

export default json;
